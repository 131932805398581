import { isNil } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from '../../../shared/view/components/Modal/Modal';
import { useSecureChallengeStore } from '../domain/secureChallenge.store';
import { useListenerToSecureChallenge } from '../domain/secureMessages';
import { BlockedPopup } from './BlockedPopup';
import { DoneFailed } from './DoneFailed';
import { DoneSuccess } from './DoneSuccess';
import { Ongoing } from './Ongoing';
import { Transferring } from './Transferring';

type Status =
  | 'None'
  | 'Started'
  | 'Blocked'
  | 'Ongoing'
  | 'Succeeded'
  | 'Failed';

export const SecureChallengePopup = () => {
  const { challengeUrl, endSecureChallenge } = useSecureChallengeStore(
    (state) => ({
      challengeUrl: state.challengeUrl,
      endSecureChallenge: state.endSecureChallenge,
    }),
  );
  const bankWindowRef = useRef<Window | null>(null);
  const [status, setStatus] = useState<Status>('Started');

  useListenerToSecureChallenge({
    onSuccess: () => {
      setStatus('Succeeded');
      closeSecureChallenge(true, true);
    },
    onFailure: () => {
      setStatus('Failed');
      closeSecureChallenge(true, true);
    },
  });

  const openBankInterface = useCallback(
    (url: string) => {
      const bankWindow = window.open(url, '_blank');
      if (isNil(bankWindow)) {
        setStatus('Blocked');
      } else {
        bankWindowRef.current = bankWindow;
        setStatus('Ongoing');
      }
    },
    [setStatus],
  );

  const closeSecureChallenge = (
    success: boolean,
    delayClosingPopup: boolean,
  ) => {
    const delay = delayClosingPopup ? 5000 : 0;
    setTimeout(() => {
      bankWindowRef.current?.close();
      bankWindowRef.current = null;
      setStatus(success ? 'Succeeded' : 'Failed');
      endSecureChallenge(success);
    }, delay);
  };

  const focusOpenedWindow = () => {
    bankWindowRef.current?.focus();
  };

  useEffect(() => {
    if (challengeUrl) {
      openBankInterface(challengeUrl);
    } else {
      setStatus('None');
    }
  }, [challengeUrl, setStatus, openBankInterface]);

  if (!challengeUrl) {
    return null;
  }
  return (
    <Modal show={true} onClose={() => closeSecureChallenge(false, false)}>
      {status === 'Started' && (
        <Transferring
          onCancel={() => {
            closeSecureChallenge(false, false);
          }}
        />
      )}
      {status === 'Blocked' && (
        <BlockedPopup
          openSecureChallenge={() => openBankInterface(challengeUrl)}
        />
      )}
      {status === 'Ongoing' && (
        <Ongoing
          onCancel={() => {
            closeSecureChallenge(false, false);
          }}
          onOpenSecureChallenge={() => focusOpenedWindow()}
        />
      )}
      {status === 'Succeeded' && (
        <DoneSuccess
          onClick={() => {
            closeSecureChallenge(true, true);
          }}
        />
      )}
      {status === 'Failed' && (
        <DoneFailed
          onClick={() => {
            closeSecureChallenge(false, true);
          }}
        />
      )}
    </Modal>
  );
};
