import { t } from '@lingui/macro';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEnrollmentStore } from '../../../../modules/Enrollment/domain/enrollment.store';
import { useAddToast } from '../../../../modules/toaster/domain/toaster.store';

export const SetupEnrollment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const setEnrollmentId = useEnrollmentStore((state) => state.setEnrollmentId);
  const { addToast } = useAddToast();

  useEffect(() => {
    if (id) {
      setEnrollmentId(id);
      navigate('../w');
    } else {
      addToast({
        id: 'enrollmentNotFound',
        message: t`error.default`,
      });
    }
  }, [id, navigate, setEnrollmentId, addToast]);
  return null;
};
