import { gql } from '@apollo/client';

export const GET_ENROLLMENT = gql`
  query GetEnrollment($enrollmentId: ID!) {
    enrollment(id: $enrollmentId) {
      id
      status
      patient {
        id
      }
      subscription {
        paymentType
        activatedAt
        id
        status
        subscriptionPayments {
          amount
          cbLastDigits
          completedAt
        }
        spreeOrder {
          state
          token
          total
          number
          paymentMethodId
          paymentMethods {
            id
            name
          }
        }
      }
      availableCercles {
        id
        seatsLeft
        price
        plannedStartAt
        installmentPrice
      }
      spreeOrder {
        state
        token
        total
        number
        paymentMethodId
        paymentMethods {
          id
          name
        }
      }
    }
  }
`;
