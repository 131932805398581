import type { BaseMutationOptions } from '@apollo/client';
import { t } from '@lingui/macro';
import type { FunctionComponent } from 'react';
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import type { SubmitApplicationMutation } from '../../../shared/infra/queries.types';
import { Input } from '../../../shared/view/components/Input/Input';
import { Toggle } from '../../../shared/view/components/Toggle/Toggle';
import { Typography } from '../../../shared/view/components/Typography/Typography';
import { useWorkflowNavigation } from '../../../shared/view/components/Workflow';
import { useAddToast } from '../../toaster/domain/toaster.store';
import { useMotivationSurveyPageCount } from '../shared/infra/useMotivationSurveyPageCount';
import { useSubmitApplication } from '../shared/infra/useSubmitApplication';
import { OnboardingTemplateScreen } from '../shared/view/OnboardingTemplateScreen';
import type { SignInForm } from './domain/useSignInForm';
import { useSignInForm } from './domain/useSignInForm';

export const SignIn: FunctionComponent = () => {
  const { addToast } = useAddToast();
  const { navigation } = useWorkflowNavigation();

  const onCompleted: BaseMutationOptions<SubmitApplicationMutation>['onCompleted'] =
    (data) => {
      if (data?.submitApplication?.errors) {
        addToast({
          id: 'submissionErrors',
          message: data?.submitApplication?.errors?.message || t`error.default`,
        });
        return;
      }
      navigation.goNext();
    };

  const { submitApplication, loading } = useSubmitApplication({
    onCompleted,
  });

  const onValidSubmit = useCallback(
    async (formData?: SignInForm) => {
      if (!formData) {
        return;
      }

      submitApplication(formData);
    },
    [submitApplication],
  );

  const motivationSurveyPageCount = useMotivationSurveyPageCount();

  const { control, isFormValid, onFormSubmit } = useSignInForm({
    onValidSubmit,
  });

  return (
    <OnboardingTemplateScreen
      title={t`sign-in.title`}
      ctaLabel={t`cta.sign-in.next`}
      isCtaDisabled={!isFormValid}
      isCtaLoading={loading}
      onPress={onFormSubmit}
      progressBar={{
        currentStep: 1 + motivationSurveyPageCount,
        maxStep: 3 + motivationSurveyPageCount,
        label: t`motivationSurvey.progress-bar`,
      }}
    >
      <Controller
        control={control}
        name="email"
        render={({
          field: { onChange, value, onBlur },
          fieldState: { error, isTouched, isDirty },
        }) => (
          <Input
            type="email"
            name="email"
            value={value}
            onChangeText={onChange}
            onBlur={isDirty ? onBlur : undefined}
            placeholder={t`sign-in.email.placeholder`}
            isError={!!error && isTouched && isDirty}
            errorLabel={error?.message}
            autoComplete="email"
          />
        )}
      />
      <div className="h-8" />
      <div className="hidden">
        <Controller
          control={control}
          name="password"
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error, isTouched, isDirty },
          }) => (
            <Input
              type="hidden"
              name="password"
              value={value}
              onChangeText={onChange}
              onBlur={isDirty ? onBlur : undefined}
              placeholder={t`sign-in.password.placeholder`}
              isError={!!error && isTouched && isDirty}
              autoComplete="new-password"
            />
          )}
        />
      </div>

      <div className="h-8 flex-grow" />
      <Controller
        control={control}
        name="hasAcceptedToBeContacted"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <div className="flex-col">
            <div className="flex flex-row justify-between items-center">
              <Typography.P2>{t`sign-in.has-accepted-to-be-contacted`}</Typography.P2>
              <div className="w-16" />
              <Toggle isToggled={value} onChange={onChange} />
            </div>
            <Typography.P4 className="ml-16 text-orange-400">
              {!!error && t`sign-in.has-accepted-to-be-contacted.error`}
            </Typography.P4>
          </div>
        )}
      />
    </OnboardingTemplateScreen>
  );
};
