import { useRoutes } from 'react-router-dom';
import { CardRegister } from '../../../modules/Enrollment/CardRegister.screen';
import { WelcomePostFreeTrialScreen } from '../../../modules/Enrollment/WelcomePostFreeTrial.screen';
import Workflow from '../../../shared/view/components/Workflow';
import { useSendAnalyticsPageViewOnUrlChange } from '../../Analytics/useSendAnalyticsPageViewOnUrlChange';
import { SetupEnrollment } from './SetupEnrollment';

const workflowSteps = [
  {
    path: 'souscription',
    comp: <CardRegister />,
  },
  {
    path: 'confirmation',
    comp: <WelcomePostFreeTrialScreen />,
  },
];

function Enrollment() {
  useSendAnalyticsPageViewOnUrlChange();
  return useRoutes([
    {
      path: 's/:id',
      element: <SetupEnrollment />,
    },
    {
      path: 'w/*',
      element: <Workflow steps={workflowSteps} />,
    },
  ]);
}

export default Enrollment;
