import { t } from '@lingui/macro';
import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import { logError } from '../../../helpers/logger/logError';
import { Button } from '../Button/Button';
import { Typography } from '../Typography/Typography';

type Props = {
  onRetry?: () => void;
  isRetrying?: boolean;
  error?: unknown;
};

export const ErrorPage: FunctionComponent<Props> = ({
  onRetry,
  isRetrying,
  error,
}) => {
  useEffect(() => {
    logError(error);
  }, [error]);

  return (
    <div className="flex flex-col items-center justify-center h-full text-center">
      <Typography.Title>{t`error.default`}</Typography.Title>
      <div className="h-16" />
      {onRetry && (
        <Button.Primary
          onClick={() => onRetry()}
          label={isRetrying ? t`Loading` : t`Retry`}
          isDisabled={isRetrying}
        />
      )}
    </div>
  );
};
