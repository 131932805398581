type Config = {
  environment: 'staging' | 'production';
  apiUrl: string;
  segmentWriteKey: string;
  sentryDsn: string;
};

const appConfig: Config = {
  environment:
    process.env.REACT_APP_ENVIRONMENT === 'staging' ? 'staging' : 'production',
  apiUrl: process.env.REACT_APP_API_URL ?? '',
  segmentWriteKey: process.env.REACT_APP_SEGMENT_WRITE_KEY ?? '',
  sentryDsn: process.env.REACT_APP_SENTRY_DSN ?? '',
};

export default appConfig;
