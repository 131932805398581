import { ScreenContentContainer } from '../../../../modules/Onboarding/shared/view/ScreenContentContainer';
import { ScreenTitle } from '../../../../modules/Onboarding/shared/view/ScreenTitle';
import { Typography } from '../../../../shared/view/components/Typography/Typography';
import { useGetEnrollment } from '../../../../modules/Enrollment/infra/useGetEnrollment';
import { ErrorPage } from '../../../../shared/view/components/ErrorPage/ErrorPage';
import { LoadingView } from '../../../../shared/view/components/LoadingView/LoadingView';
import { EnrollmentState } from '../../../../shared/infra/queries.types';
import ValidPaymentCard from '../../../../shared/view/components/PaymentCard/ValidPaymentCard';
import FailedPaymentCard from '../../../../shared/view/components/PaymentCard/FailedPaymentCard';
import frenchDate from '../../../../modules/Onboarding/Method/domain/adaptDate';
import { t } from '@lingui/macro';

const PaymentList = () => {
  const { enrollment, loading, error, refetch } = useGetEnrollment();
  const payments = enrollment?.subscription?.subscriptionPayments;
  const date = frenchDate(enrollment?.subscription?.activatedAt);

  if (loading) {
    return <LoadingView isLoading={loading} />;
  }

  if (error || !enrollment || !enrollment.subscription?.spreeOrder) {
    return <ErrorPage error={error} onRetry={refetch} isRetrying={loading} />;
  }

  return (
    <ScreenContentContainer>
      <div className="pb-24 w-full">
        <ScreenTitle text={t`subscription.screen.title`} />
        <div className="h-4" />
        <Typography.P2 className="text-center text-grey-0 font-semibold">
          {t`subscription.screen.description ${date}`}
        </Typography.P2>
        <div className="h-12" />
        <div className="flex flex-col gap-16">
          {enrollment?.status === EnrollmentState.Interrupted && (
            <FailedPaymentCard
              price={payments?.[0].amount || '0'}
              lastDigits={payments?.[0].cbLastDigits || '0000'}
              order={enrollment.subscription.spreeOrder}
              refetchEnrollment={refetch}
            />
          )}
          {payments?.map((payment, i) => (
            <ValidPaymentCard
              price={payment.amount || '0'}
              date={frenchDate(payment.completedAt)}
              key={`payment-card-${i}`}
            />
          ))}
        </div>
      </div>
    </ScreenContentContainer>
  );
};

export default PaymentList;
