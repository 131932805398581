import { memo } from 'react';

const FlipLeft: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <circle
        cx="10.508"
        cy="10.508"
        r="10.507"
        fill="#0B0C0C"
        transform="rotate(45 10.508 10.508)"
      />
      <path
        fill="#3EC7AE"
        d="M6.849 13.195l.655.656 2.6-2.6 2.599 2.6.656-.656-2.6-2.6 2.6-2.599-.656-.656-2.6 2.6-2.599-2.6-.655.656 2.599 2.6-2.6 2.599z"
      />
    </svg>
  );
};

export const FlipLeftIcon = memo(FlipLeft);
FlipLeftIcon.displayName = 'FlipLeftIcon';
