import { useWorkflowNavigation } from '../../../../shared/view/components/Workflow';
import { BackButton } from './BackButton';

export const BackHeader = () => {
  const { navigation } = useWorkflowNavigation();
  return (
    <div className="flex pb-16 items-center w-full">
      <BackButton onClick={navigation.goPrevious} />
    </div>
  );
};
