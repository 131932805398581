import { useRoutes } from 'react-router-dom';
import Workflow from '../../../shared/view/components/Workflow';
import { useSendAnalyticsPageViewOnUrlChange } from '../../Analytics/useSendAnalyticsPageViewOnUrlChange';
import { SetupEnrollment } from '../Enrollment/SetupEnrollment';
import PaymentList from './PaymentList';

const workflowSteps = [
  {
    path: '',
    comp: <PaymentList />,
  },
];

const Subscription = () => {
  useSendAnalyticsPageViewOnUrlChange();
  return useRoutes([
    {
      path: 's/:id',
      element: <SetupEnrollment />,
    },
    {
      path: 'w/*',
      element: <Workflow steps={workflowSteps} />,
    },
  ]);
};

export default Subscription;
