import type { PropsWithChildren } from 'react';
import { BackHeader } from './BackHeader';
import { ScreenContentContainer } from './ScreenContentContainer';
import { ScreenSubTitle } from './ScreenSubTitle';
import { ScreenTitle } from './ScreenTitle';
import { StickyFooter } from './StickyFooter';

type ProgressProps = {
  label: string;
  currentStep: number;
  maxStep: number;
};

type Props = PropsWithChildren<{
  title?: string;
  subtitle?: string;
  ctaLabel?: string;
  ctaLabelSecondary?: string;
  onPress?: () => void;
  onPressSecondary?: () => void;
  isCtaDisabled?: boolean;
  isCtaLoading?: boolean;
  progressBar?: ProgressProps;
}>;

export const OnboardingTemplateScreen = ({
  title,
  subtitle,
  children,
  ctaLabel,
  ctaLabelSecondary,
  onPress,
  onPressSecondary,
  isCtaDisabled = false,
  isCtaLoading = false,
  progressBar,
}: Props) => {
  return (
    <ScreenContentContainer>
      <div className="w-full flex flex-col flex-1 pb-[80px]">
        <BackHeader />
        {title && (
          <>
            <div className="h-4" />
            <ScreenTitle text={title} />
          </>
        )}
        {subtitle ? (
          <>
            <div className="h-4" />
            <ScreenSubTitle text={subtitle} />
          </>
        ) : null}
        {title && <div className="h-24" />}
        {children}
      </div>

      <StickyFooter
        nextButtonProps={
          ctaLabel
            ? {
                label: ctaLabel,
                onPress,
                isLoading: isCtaLoading,
                isDisabled: isCtaDisabled,
              }
            : undefined
        }
        SecondaryNextButtonProps={
          ctaLabelSecondary
            ? {
                label: ctaLabelSecondary,
                onPress: onPressSecondary,
                isLoading: isCtaLoading,
                isDisabled: isCtaDisabled,
              }
            : undefined
        }
        progressBarProps={progressBar}
      />
    </ScreenContentContainer>
  );
};
