import { memo } from 'react';

const LeftChevronIconSvg: React.FC = () => {
  return (
    <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
      <path
        d="M5 1L1 6L5 11"
        stroke="#FDFDFD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LeftChevronIcon = memo(LeftChevronIconSvg);
LeftChevronIcon.displayName = 'LeftChevron';
