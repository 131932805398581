import { usePrefetchProblems } from '../../../../modules/Onboarding/Medical/infra/usePrefetchProblems';
import { MedicalSurveyScreen } from '../../../../modules/Onboarding/Medical/MedicalSurvey.screen';
import { SelectProblemScreen } from '../../../../modules/Onboarding/Medical/SelectProblem.screen';
import { SelectSubProblemScreen } from '../../../../modules/Onboarding/Medical/SelectSubProblem.screen';
import { ErrorPage } from '../../../../shared/view/components/ErrorPage/ErrorPage';
import { LoadingView } from '../../../../shared/view/components/LoadingView/LoadingView';
import { NestedWorkflow } from '../../../../shared/view/components/Workflow';

const Medical = () => {
  const {
    shouldShowSelectProblemScreen,
    shouldShowSelectSubProblemScreen,
    loading,
    error,
    refetch,
  } = usePrefetchProblems();

  if (error) {
    return <ErrorPage error={error} onRetry={refetch} isRetrying={loading} />;
  }

  if (loading) {
    return <LoadingView isLoading />;
  }

  return (
    <NestedWorkflow
      steps={[
        {
          path: 'selectproblem',
          comp: <SelectProblemScreen />,
          show: () => shouldShowSelectProblemScreen,
        },
        {
          path: 'selectsubproblem',
          comp: <SelectSubProblemScreen />,
          show: () => shouldShowSelectSubProblemScreen,
        },
        {
          path: 'survey',
          comp: <MedicalSurveyScreen />,
        },
      ]}
    />
  );
};

export default Medical;
