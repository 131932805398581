import { isNil } from 'lodash';

export const isInWebView = (): boolean => {
  return !isNil(window.ReactNativeWebView);
};

type WebviewPayloadNavigation = {
  type: 'navigation';
  action: 'back_called_on_first_page' | 'completed_onboarding';
};

export const postMessageToReactNative = (msg: object): void => {
  if (isInWebView()) {
    window.ReactNativeWebView.postMessage(JSON.stringify(msg));
  }
};

export const postGoBackOnFirstPage = () => {
  postMessageToReactNative({
    type: 'navigation',
    action: 'back_called_on_first_page',
  } satisfies WebviewPayloadNavigation);
};

export const postCompleteOnboarding = () => {
  postMessageToReactNative({
    type: 'navigation',
    action: 'completed_onboarding',
  } satisfies WebviewPayloadNavigation);
};
