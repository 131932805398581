import { GreenPill } from '../Pill/Pill';
import { frenchPrice } from '../../../../modules/Onboarding/Method/domain/adaptPrice';
import { t } from '@lingui/macro';

type Props = {
  date: string;
  price: string;
};

const ValidPaymentCard = ({ date, price }: Props) => {
  return (
    <div className="flex flex-col bg-grey-800 rounded-2xl p-16 border border-grey-600">
      <div className="flex items-center justify-between">
        <GreenPill text={t`pill.payment.paid ${date}`} />
        <div className="text-grey-0 font-semibold text-title-s">
          {frenchPrice(price)}
        </div>
      </div>
    </div>
  );
};

export default ValidPaymentCard;
