import { marked } from 'marked';

const renderer = new marked.Renderer();
renderer.strong = (text: string) => {
  return `<span class="text-teal-300">${text}</span>`;
};
renderer.codespan = (text: string) => {
  return `<span class="bg-teal-500 rounded-md p-2 text-grey-900">${text}</span>`;
};

marked.use({ renderer: renderer });

export { marked };
