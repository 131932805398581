import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type OnboardingAnswers = {
  protocolCategoryId: string | undefined;
  protocolId: string | undefined;
  protocolName: string | undefined;
  medicalSurveyAnswers: Record<string, string> | undefined;
  motivationSurveyAnswers: Record<string, string> | undefined;
};

type OnboardingAnswersStore = OnboardingAnswers & {
  setOnboardingAnswers: (answer: Partial<OnboardingAnswers>) => void;
};

export const useOnboardingStore = create<OnboardingAnswersStore>()(
  persist(
    (set) => {
      return {
        protocolCategoryId: undefined,
        protocolId: undefined,
        protocolName: undefined,
        medicalSurveyAnswers: undefined,
        motivationSurveyAnswers: undefined,
        setOnboardingAnswers: (answer) => set(() => answer),
      };
    },
    { name: 'onboarding' },
  ),
);
