import { useWeightLossEstimation } from '../../infra/useWeightLossEstimation';
import { GraphWeightSvg } from './GraphWeightSvg';

export const GraphWeight = () => {
  const { initialWeight, weightLossEstimation } = useWeightLossEstimation();

  if (!weightLossEstimation) {
    return null;
  }

  const goalWeight = initialWeight - weightLossEstimation;

  return (
    <div data-testid="graph-svg">
      <GraphWeightSvg initialWeight={initialWeight} goalWeight={goalWeight} />
    </div>
  );
};
