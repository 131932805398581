import { memo } from 'react';

const RightChevronIconSvg: React.FC = () => {
  return (
    <svg width="6" height="12" fill="none">
      <path
        fill="#7C7D7D"
        fillRule="evenodd"
        d="M.53 11.59a.75.75 0 0 1-.12-1.06L4.04 6 .41 1.47A.75.75 0 1 1 1.6.53l4 5c.21.28.21.66 0 .94l-4 5a.75.75 0 0 1-1.06.12Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const RightChevronIcon = memo(RightChevronIconSvg);
RightChevronIcon.displayName = 'RightChevron';
