import { memo } from 'react';

type Props = {
  color?: string;
};

const ErrorIconSvg = ({ color = 'rgb(243 94 60)' }: Props) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      data-test-id="error-icon"
    >
      <circle cx="8" cy="11.33" r=".67" fill={color} />
      <path
        stroke={color}
        strokeLinecap="round"
        d="M8 9.33V4.67M2 6.09V9.9c0 1.02.53 1.96 1.39 2.46L6.6 14.3c.86.5 1.92.5 2.78 0l3.22-1.92A2.86 2.86 0 0 0 14 9.91V6.1c0-1.02-.53-1.96-1.39-2.46L9.4 1.7c-.86-.5-1.92-.5-2.78 0L3.4 3.63A2.86 2.86 0 0 0 2 6.09Z"
      />
    </svg>
  );
};

export const ErrorIcon = memo(ErrorIconSvg);
ErrorIcon.displayName = 'Error';
