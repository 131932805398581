import Workflow from '../../shared/view/components/Workflow';
import { useSendAnalyticsPageViewOnUrlChange } from '../Analytics/useSendAnalyticsPageViewOnUrlChange';
import { OnboardingV2 } from './Onboarding/OnboardingV2';

const workflowSteps = [
  // {
  //   path: 'ft',
  //   comp: <OnboardingFreeTrial />,
  // },
  {
    path: 'onboarding',
    comp: <OnboardingV2 />,
  },
];

export const WorkflowV2OrFreeTrial = () => {
  useSendAnalyticsPageViewOnUrlChange();

  const shouldEnableFreeTrial =
    process.env.REACT_APP_FREE_TRIAL_ENABLED === '1';

  const actualSteps = shouldEnableFreeTrial
    ? workflowSteps
    : [...workflowSteps].reverse();

  return <Workflow steps={actualSteps} />;
};
