import { useCallback } from 'react';
import { create } from 'zustand';

const TOAST_TIMEOUT_MS = 3000;

export type Toast = {
  id: string | number;
  message: string;
};

type ToasterState = {
  toasts: Toast[];
  addToast: (toast: Toast) => boolean;
  removeToast: (toastId: Toast['id']) => void;
};

export const useToasterStore = create<ToasterState>()((set) => ({
  toasts: [] as Toast[],

  addToast: (toast) => {
    let isToastAlreadyAdded = false;
    set((state) => {
      if (state.toasts.some((t) => t.id === toast.id)) {
        isToastAlreadyAdded = true;
        return { toasts: state.toasts };
      }
      return { toasts: [...state.toasts, toast] };
    });
    return isToastAlreadyAdded;
  },
  removeToast: (toastId) =>
    set((state) => ({
      toasts: state.toasts.filter((toast) => toast.id !== toastId),
    })),
}));

export const useAddToast = () => {
  const { addToast, removeToast } = useToasterStore((state) => ({
    addToast: state.addToast,
    removeToast: state.removeToast,
  }));

  const addToastWithIdAndTimeout = useCallback(
    (
      toast: Omit<Toast, 'id'> & { id?: Toast['id'] },
      timeout = TOAST_TIMEOUT_MS,
    ) => {
      const id = toast.id ?? Date.now();
      const wasToastAlreadyAdded = addToast({ ...toast, id });
      if (!wasToastAlreadyAdded) {
        setTimeout(() => removeToast(id), timeout);
      }
    },
    [addToast, removeToast],
  );

  return { addToast: addToastWithIdAndTimeout };
};
