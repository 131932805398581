import { SpinnerIcon } from '../../icons/Spinner.icon';

type ButtonProps = {
  onClick: () => void;
  label: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
};

const Primary = ({
  label,
  isDisabled,
  isLoading = false,
  onClick,
  className = '',
}: ButtonProps) => {
  return (
    <button
      disabled={isDisabled}
      className={`bg-grey-0 disabled:bg-grey-700 active:bg-grey-100 rounded-xl text-grey-900 disabled:text-grey-400 p-12 ${className}`}
      onClick={onClick}
    >
      {isLoading ? (
        <div className="flex justify-center">
          <SpinnerIcon className="animate-spin stroke-grey-900" />
        </div>
      ) : (
        label
      )}
    </button>
  );
};

const Secondary = ({
  label,
  isDisabled,
  isLoading = false,
  onClick,
  className = '',
}: ButtonProps) => {
  return (
    <button
      disabled={isDisabled}
      className={`bg-grey-800 active:bg-grey-700 disabled:bg-grey-900 border-2 border-grey-700 disabled:border-grey-800 rounded-xl text-grey-0 disabled:text-grey-500 p-12 ${className}`}
      onClick={onClick}
    >
      {isLoading ? (
        <div className="flex justify-center">
          <SpinnerIcon className="animate-spin" />
        </div>
      ) : (
        label
      )}
    </button>
  );
};

const Tertiary = ({
  label,
  isDisabled,
  isLoading = false,
  onClick,
  className = '',
}: ButtonProps) => {
  return (
    <button
      disabled={isDisabled}
      className={`bg-grey-900 active:bg-grey-800 rounded-xl text-grey-0 disabled:text-grey-500 p-12 ${className}`}
      onClick={onClick}
    >
      {isLoading ? (
        <div className="flex justify-center">
          <SpinnerIcon className="animate-spin" color="#FDFDFD" />
        </div>
      ) : (
        label
      )}
    </button>
  );
};

export const Button = {
  Primary,
  Secondary,
  Tertiary,
};
