import { memo } from 'react';

const FlipRight: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <circle cx="10.507" cy="10.507" r="10.507" fill="#3EC7AE" />
      <g clipPath="url(#clip0_0_1)">
        <path
          fill="#0B0C0C"
          fillRule="evenodd"
          d="M11.27 15.479a.349.349 0 000-.698h-1.13l3.021-3.021a2.674 2.674 0 000-3.782l-.328-.328a.349.349 0 10-.494.493l.33.329a1.976 1.976 0 010 2.795L9.05 14.883a.349.349 0 00.247.596h1.973zM9.299 6.232a.349.349 0 100 .698h1.13L7.409 9.95a2.674 2.674 0 000 3.782l.328.328a.349.349 0 10.493-.493L7.9 13.24a1.976 1.976 0 010-2.795l3.617-3.616a.349.349 0 00-.246-.596H9.298z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_1">
          <path
            fill="#fff"
            d="M0 0H11.16V11.16H0z"
            transform="rotate(135 6.84 9.192)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FlipRightIcon = memo(FlipRight);
FlipRightIcon.displayName = 'FlipRightIcon';
