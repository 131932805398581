import { gql } from '@apollo/client';

export const GET_SURVEYS = gql`
  query GetSurveys($protocolId: ID!) {
    protocol(id: $protocolId) {
      id
      surveys {
        title
        payload
        type
      }
    }
  }
`;
