import { isUndefined } from 'lodash';
import type {
  ExistingCardPayload,
  MangopayBrowserInfo,
  PaymentMethodWithData,
  PaymentPayload,
  PaymentRequestPayload,
} from './payment.types';

const getBrowserInfo = (): MangopayBrowserInfo => {
  const navigator = window.navigator;
  const screen = window.screen;
  return {
    javaEnabled: navigator.javaEnabled(),
    language: navigator.language,
    colorDepth: screen.colorDepth,
    screenHeight: screen.height,
    screenWidth: screen.width,
    timeZoneOffset: new Date().getTimezoneOffset().toString(),
    userAgent: navigator.userAgent,
    javascriptEnabled: true,
  };
};

export const createPaymentRequestPayload = (
  paymentSourcePayload: PaymentPayload,
): PaymentRequestPayload => {
  if (isUndefined((paymentSourcePayload as ExistingCardPayload).existingCard)) {
    const payload = paymentSourcePayload as PaymentMethodWithData;
    const paymentMethodId = payload.paymentMethodId;
    return {
      paymentSource: {
        [paymentMethodId]: payload.data,
      },
      order: {
        paymentsAttributes: [
          {
            paymentMethodId,
          },
        ],
      },
      mangopayBrowserInfo: getBrowserInfo(),
    };
  } else {
    const payload = paymentSourcePayload as ExistingCardPayload;
    return {
      order: {
        existingCard: payload.existingCard,
      },
      mangopayBrowserInfo: getBrowserInfo(),
    };
  }
};
