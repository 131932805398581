import { gql } from '@apollo/client';

export const GET_PROBLEMS = gql`
  query GetProblems {
    protocolCategories {
      id
      title
      protocols {
        id
        title
      }
    }
  }
`;
