import { ApolloProvider as ApolloClientProvider } from '@apollo/client';
import { apolloClient } from './ApolloClient.singleton';

type ApolloProviderProps = {
  children: React.ReactNode;
};

export const ApolloProvider = ({ children }: ApolloProviderProps) => (
  <ApolloClientProvider client={apolloClient}>{children}</ApolloClientProvider>
);
