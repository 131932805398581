import { useQuery } from '@apollo/client';
import type {
  GetProblemsQuery,
  GetProblemsQueryVariables,
} from '../../../../shared/infra/queries.types';
import { GET_PROBLEMS } from './getProblems.query';

export const useGetProblems = () => {
  const { data, loading, error, refetch } = useQuery<
    GetProblemsQuery,
    GetProblemsQueryVariables
  >(GET_PROBLEMS);
  return { data, loading, error, refetch };
};
