import { useQuery } from '@apollo/client';
import { Model } from 'survey-core';
import type {
  GetSurveysQuery,
  GetSurveysQueryVariables,
  SurveyType,
} from '../../../../shared/infra/queries.types';
import { i18n } from '../../../../app/i18n/I18n.singleton';
import { useOnboardingStore } from '../domain/onboarding.store';
import { GET_SURVEYS } from './getSurveys.query';
import 'survey-core/survey.i18n';

export const useGetSurveys = (surveyType: SurveyType) => {
  const protocolId = useOnboardingStore((state) => state.protocolId);

  const { data, loading, error, refetch } = useQuery<
    GetSurveysQuery,
    GetSurveysQueryVariables
  >(GET_SURVEYS, {
    variables: {
      protocolId: protocolId ?? '',
    },
    skip: !protocolId,
  });
  return {
    surveyData: data?.protocol?.surveys?.find(
      (survey) => survey.type === surveyType,
    ),
    loading,
    error,
    refetch,
  };
};

export const createSurveyModel = (payload: string | null | undefined) => {
  try {
    const surveyJson = JSON.parse(payload ?? '');
    const model = new Model(surveyJson);
    model.locale = i18n.locale;

    return model;
  } catch (err) {
    return null;
  }
};
