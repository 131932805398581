import type { FunctionComponent } from 'react';
import type { NextButtonProps } from '../../Presentation/view/NextButton';
import { NextButton } from '../../Presentation/view/NextButton';
import type { ProgressBarProps } from './ProgressBar';
import { ProgressBar } from './ProgressBar';
import { showProgressBar } from '../../../../shared/config/constants';

type Props = {
  progressBarProps?: ProgressBarProps | undefined;
  nextButtonProps?: NextButtonProps;
  SecondaryNextButtonProps?: NextButtonProps;
};

export const StickyFooter: FunctionComponent<Props> = ({
  progressBarProps,
  nextButtonProps,
  SecondaryNextButtonProps,
}) => {
  return (
    <div className="w-full fixed bg-grey-900 bottom-0 p-16 max-w-[535px]">
      {SecondaryNextButtonProps && nextButtonProps ? (
        <div className="flex space-x-8">
          <NextButton {...nextButtonProps} />
          <NextButton {...SecondaryNextButtonProps} />
        </div>
      ) : null}
      {nextButtonProps && !SecondaryNextButtonProps ? (
        <NextButton {...nextButtonProps} />
      ) : null}
      {/* to be removed in the future */}
      {progressBarProps && showProgressBar ? (
        <div className={`bg-grey-900 ${nextButtonProps ? 'pt-16' : 'pt-16'}`}>
          <ProgressBar {...progressBarProps} />
        </div>
      ) : null}
    </div>
  );
};
