export const supportPhoneNumber = '01 89 74 01 31';
export const supportPhoneNumberLink = `tel:+33${supportPhoneNumber
  .replace(/\s/g, '')
  .substring(1)}`;

export const supportEmail = 'support@joincercle.com';
export const supportEmailLink = `mailto:${supportEmail}`;

// to be deleted when we switch to multiple protocols
export const preSelectedCategory = 'Poids';
export const showProgressBar = false;
