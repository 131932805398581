import { t } from '@lingui/macro';
import { useWorkflowNavigation } from '../../shared/view/components/Workflow';
import { useAddToast } from '../toaster/domain/toaster.store';

import { ErrorPage } from '../../shared/view/components/ErrorPage/ErrorPage';
import { Typography } from '../../shared/view/components/Typography/Typography';
import { OnboardingTemplateScreen } from '../Onboarding/shared/view/OnboardingTemplateScreen';
import type { SubmitCreditCardCallback } from './domain/payment/payment.rest';

import { LoadingView } from '../../shared/view/components/LoadingView/LoadingView';
import { useGetEnrollment } from './infra/useGetEnrollment';
import { SubscriptionPaymentExplanation } from './view/SubscriptionPaymentExplanation';
import PaymentForm from '../../shared/view/components/PaymentForm/PaymentForm';
import type { FunctionComponent } from 'react';

export const PaymentScreen: FunctionComponent = () => {
  const { addToast } = useAddToast();
  const { navigation } = useWorkflowNavigation();
  const {
    enrollment,
    loading: loadingEnrollment,
    error,
    refetch,
  } = useGetEnrollment();

  const onCompleted: SubmitCreditCardCallback = (receivedOrder, error) => {
    if (receivedOrder && !error) {
      navigation.goNext();
    } else {
      addToast({
        id: 'submissionErrors',
        message: error,
      });
    }
  };

  if (loadingEnrollment) {
    return <LoadingView isLoading={loadingEnrollment} />;
  }

  if (error || !enrollment || !enrollment.subscription?.spreeOrder) {
    return (
      <ErrorPage
        error={error}
        onRetry={refetch}
        isRetrying={loadingEnrollment}
      />
    );
  }
  return (
    <OnboardingTemplateScreen
      title={t`payment.title`}
      progressBar={{
        label: t`enrollment.progress-bar`,
        currentStep: 4,
        maxStep: 4,
      }}
    >
      <SubscriptionPaymentExplanation enrollment={enrollment} />
      <div className="h-24" />
      <PaymentForm
        order={enrollment.subscription.spreeOrder}
        onCompleted={onCompleted}
      />
      <div className="h-24" />

      <Typography.P3 className="text-grey-400 leading-6">{t`payment.subtitle`}</Typography.P3>
    </OnboardingTemplateScreen>
  );
};
