import type { BaseMutationOptions } from '@apollo/client';
import { t } from '@lingui/macro';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import {
  Payment,
  type SetupSoloSubscriptionOrderMutation,
} from '../../../shared/infra/queries.types';
import { Typography } from '../../../shared/view/components/Typography/Typography';
import { useWorkflowNavigation } from '../../../shared/view/components/Workflow';
import { SpinnerIcon } from '../../../shared/view/icons/Spinner.icon';
import { useGetEnrollment } from '../../Enrollment/infra/useGetEnrollment';
import { useSubmitSetupSoloSubscriptionOrder } from '../../Enrollment/infra/useSubmitSetupSoloSubscriptionOrder';
import { useAddToast } from '../../toaster/domain/toaster.store';
import { OnboardingTemplateScreen } from '../shared/view/OnboardingTemplateScreen';
import { adaptPrice } from './domain/adaptPrice';
import { useGetPrice } from './infra/useGetPrice';
import { CheckCircleIcon } from './view/CheckCircle.icon';

export const PaymentTypeSelection: FunctionComponent = () => {
  const { navigation } = useWorkflowNavigation();
  const { addToast } = useAddToast();
  const {
    price,
    loading,
    discountOneOffOverInstallment,
    installmentPrice,
    installmentCount,
    installmentTotalPrice,
  } = useGetPrice();

  const { enrollment } = useGetEnrollment(false);
  const [paymentType, setPaymentType] = useState<
    | Payment.Installment
    | Payment.OneOff
    | Payment.ManualRenewal
    | Payment.External
  >(enrollment?.subscription?.paymentType ?? Payment.OneOff);

  const onCompleted: BaseMutationOptions<SetupSoloSubscriptionOrderMutation>['onCompleted'] =
    (data) => {
      if (data?.setupSoloSubscriptionOrder?.errors) {
        addToast({
          id: 'setupSoloSubscriptionError',
          message: t`error.default`,
        });
        return;
      }
      navigation.goNext();
    };

  const { setupSoloSubscriptionOrder } = useSubmitSetupSoloSubscriptionOrder(
    {
      onCompleted,
    },
    paymentType,
  );

  const stepCount = installmentCount;
  const fullPriceDisplay = adaptPrice(price);
  const installmentPriceDisplay = adaptPrice(installmentPrice);
  const installmentTotalPriceDisplay = adaptPrice(installmentTotalPrice);

  const oneOffClass =
    paymentType === Payment.OneOff
      ? 'border-teal-400'
      : 'border-grey-100 opacity-30';
  const installmentClass =
    paymentType === Payment.Installment
      ? 'border-teal-400'
      : 'border-grey-100 opacity-30';

  return loading ? (
    <div className="flex justify-center">
      <SpinnerIcon className="animate-spin" />
    </div>
  ) : (
    <OnboardingTemplateScreen
      title={t`payment-type.title`}
      ctaLabel={
        paymentType === Payment.Installment
          ? t`payment-type.cta.installment`
          : t`payment-type.cta.one-off`
      }
      onPress={setupSoloSubscriptionOrder}
    >
      <div className="h-full flex flex-col justify-between">
        <div>
          <ul className="mt-16">
            <li className="flex mb-24">
              <CheckCircleIcon
                size={20}
                strokeWidth={2}
                className="stroke-teal-400 mr-8"
              />
              <Typography.P2>{t`payment-type.presentation.1`}</Typography.P2>
            </li>
            <li className="flex mb-24">
              <CheckCircleIcon
                size={20}
                strokeWidth={2}
                className="stroke-teal-400 mr-8"
              />
              <Typography.P2>{t`payment-type.presentation.2`}</Typography.P2>
            </li>
            <li className="flex mb-24">
              <CheckCircleIcon
                size={20}
                strokeWidth={2}
                className="stroke-teal-400 mr-8"
              />
              <Typography.P2>{t`payment-type.presentation.3`}</Typography.P2>
            </li>
            <li className="flex mb-24">
              <CheckCircleIcon
                size={20}
                strokeWidth={2}
                className="stroke-teal-400 mr-8"
              />
              <Typography.P2>{t`payment-type.presentation.4`}</Typography.P2>
            </li>
          </ul>
          <div
            className={`relative border-2 rounded-xl p-16 min-h-[92px] flex flex-col justify-center cursor-pointer ${oneOffClass}`}
            onClick={() => setPaymentType(Payment.OneOff)}
          >
            <div className="absolute uppercase top-[-12px] right-[30px] bg-teal-400 rounded-lg px-16">
              <Typography.P4 className="font-semibold">{t`payment-type.best-option`}</Typography.P4>
            </div>
            <div className="flex justify-between">
              <div>
                <Typography.P2 className="font-semibold">{t`payment-type.one-off.title ${stepCount}`}</Typography.P2>
                <div />
                <Typography.P3>{t`payment-type.one-off.subtitle ${discountOneOffOverInstallment}`}</Typography.P3>
              </div>
              <div>
                <Typography.P2 className="font-semibold">
                  {fullPriceDisplay}
                </Typography.P2>
                <div />
                <Typography.P3 className="text-teal-400 line-through">
                  {installmentTotalPriceDisplay}
                </Typography.P3>
              </div>
            </div>
          </div>
          <div className="h-24" />
          <div
            className={`border-2 rounded-xl p-16 min-h-[92px] flex flex-col justify-center cursor-pointer ${installmentClass}`}
            onClick={() => setPaymentType(Payment.Installment)}
          >
            <div className="flex justify-between">
              <Typography.P2 className="font-semibold">{t`payment-type.installment.title`}</Typography.P2>
              <Typography.P2 className="font-semibold">{t`payment-type.installment.price ${installmentPriceDisplay}`}</Typography.P2>
            </div>
          </div>
        </div>
        {paymentType === Payment.Installment && (
          <div className="text-center">
            <Typography.P2 className="font-semibold">{t`payment-type.installment.cancellable-anytime`}</Typography.P2>
          </div>
        )}
      </div>
    </OnboardingTemplateScreen>
  );
};
