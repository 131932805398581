import { Typography } from '../../../../shared/view/components/Typography/Typography';
import { RightChevronIcon } from '../../../../shared/view/icons/RightChevronIcon';

type Props = {
  label: string;
  onClick: () => void;
  className: string;
};

export const ProblemItem = ({ label, onClick, className }: Props) => {
  return (
    <div
      onClick={onClick}
      role="button"
      className={`p-12 cursor-pointer hover:bg-grey-700 w-full font-medium rounded-lg flex justify-between items-center ${className}`}
    >
      <Typography.P2>{label}</Typography.P2>
      <RightChevronIcon />
    </div>
  );
};
