import { OrangePill } from '../Pill/Pill';
import { Button } from '../Button/Button';
import { useState } from 'react';
import type { SubmitCreditCardCallback } from '../../../../modules/Enrollment/domain/payment/payment.rest';
import { useAddToast } from '../../../../modules/toaster/domain/toaster.store';
import PaymentForm from '../PaymentForm/PaymentForm';
import { Typography } from '../Typography/Typography';
import { frenchPrice } from '../../../../modules/Onboarding/Method/domain/adaptPrice';
import type { GetEnrollmentQuery } from '../../../infra/queries.types';
import { WarningIcon } from '../../icons/Warning.icon';
import { t } from '@lingui/macro';

type Props = {
  price: string;
  lastDigits: string;
  refetchEnrollment: () => void;
  order: NonNullable<
    NonNullable<
      NonNullable<GetEnrollmentQuery['enrollment']>['subscription']
    >['spreeOrder']
  >;
};

enum CardStatus {
  DEFAULT = 'DEFAULT',
  FORM = 'FORM',
  FAILED = 'FAILED',
}

const FailedPaymentCard = ({
  price,
  lastDigits,
  order,
  refetchEnrollment,
}: Props) => {
  const [status, setStatus] = useState<CardStatus>(CardStatus.DEFAULT);
  const { addToast } = useAddToast();

  const onCompleted: SubmitCreditCardCallback = (receivedOrder, error) => {
    if (receivedOrder && !error) {
      refetchEnrollment();
    } else {
      setStatus(CardStatus.FAILED);
      addToast({
        id: 'submissionErrors',
        message: error,
      });
    }
  };

  return (
    <div className="flex flex-col bg-grey-800 rounded-2xl p-16 border border-grey-600">
      {status === CardStatus.FAILED ? (
        <div className="flex flex-col items-center">
          <WarningIcon />
          <div className="h-40" />
          <Typography.P1 className="font-semibold text-title-s">
            {t`subscription.card.payment.failed.title`}
          </Typography.P1>
          <div className="h-24" />
          <div className="text-center text-grey-0">
            {t`subscription.card.payment.failed.description`}
          </div>
          <div className="h-24" />
          <Button.Primary
            className="w-full font-semibold "
            label={t`cta.payment.update`}
            onClick={() => setStatus(CardStatus.FORM)}
          />
        </div>
      ) : status === CardStatus.FORM ? (
        <>
          <Typography.P1 className="font-semibold text-title-s">
            {t`subscription.card.payment.form.title`}
          </Typography.P1>
          <div className="h-16" />
          <PaymentForm
            order={order}
            showCGU={false}
            onCompleted={onCompleted}
          />
        </>
      ) : (
        <>
          <div className="flex items-center justify-between">
            <OrangePill text={t`pill.payment.failed`} />
            <div className="text-grey-0 font-semibold text-title-s">
              {frenchPrice(price)}
            </div>
          </div>
          <div>
            <div className="h-16" />
            <div className="text-grey-300">
              {t`subscription.card.default.failed.description ${lastDigits}`}
            </div>
            <div className="h-16" />
            <Button.Primary
              className="w-full font-semibold "
              label={t`cta.payment.update`}
              onClick={() => setStatus(CardStatus.FORM)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FailedPaymentCard;
