import { createContext, useContext } from 'react';
import {
  Navigate,
  UNSAFE_RouteContext,
  useNavigate,
  useRoutes,
} from 'react-router';

import { findIndex, first, isEmpty, isNil, isUndefined, last } from 'lodash';
import './index.css';
import type * as T from './types';

export const WorkflowContext = createContext<T.WorkflowNavigation>({
  navigation: {
    goNext: () => null,
    goPrevious: () => null,
    complete: () => null,
    goBack: () => null,
    navigate: () => null,
    currentStep: 0,
    stepIndex: 0,
    steps: new Array<T.WorkflowStep>(),
    stepperStyle: 'none',
  },
});

export const useWorkflowNavigation = () => {
  return useContext(WorkflowContext);
};

const Workflow = (props: T.WorkflowProps) => {
  const navigate = useNavigate();

  /*
  Evaluate at last moment if we have enough data to show step.
  - if we have enough data, we can skip the step. We need to
    recall that when clicking previous
  - if we don't, the step is shown.
  */
  const shouldShow = (stepIndex: number): boolean => {
    const { steps } = props;
    const requestedStep: T.WorkflowStep = steps[stepIndex];
    const isLastStep = stepIndex === steps.length - 1;
    const shouldShow =
      isLastStep ||
      isUndefined(requestedStep.show) ||
      requestedStep.show(props);
    return shouldShow;
  };

  const skipStep = (stepIndex: number): void => {
    const { steps } = props;
    const requestedStep: T.WorkflowStep = steps[stepIndex];
    requestedStep.onSkip && requestedStep.onSkip(props);
  };

  /*
  To find the current step we could keep a currentStep index in the state, but it would be lost on reloads
  So the idea here is to rely exclusively on finding the currentStep thanks to the URLs, just as React Router does
  The API useMatches seems promising but is only for Routers that support the Data API.
  Thus we use the route context, although it is marked unsafe at the time of writing 01/02/2023
  */
  const routeContext = useContext(UNSAFE_RouteContext);
  const findCurrentStep = (): number => {
    const { steps } = props;
    const match = last(routeContext.matches);
    if (!isNil(match)) {
      const fullChildPath = match.params['*'] || '';
      const immediateChildPath = first(fullChildPath.split('/'));
      const currentStepIndex = findIndex(
        steps,
        (step) => step.path === immediateChildPath,
      );
      return currentStepIndex;
    } else {
      // Should probably throw an error here
      return 0;
    }
  };

  const goNext = (): void => {
    const { steps } = props;
    const currentStep = findCurrentStep();
    if (currentStep === steps.length - 1) {
      props.complete && props.complete();
    } else {
      anyGoNext(currentStep);
    }
  };

  const goPrevious = (): void => {
    const currentStep = findCurrentStep();
    if (currentStep === 0) {
      props.goBack && props.goBack();
    } else {
      anyGoPrevious();
    }
  };

  const anyGoNext = (step: number): void => {
    const { steps } = props;

    let foundStep = -1;
    let nextStep = step + 1;
    while (foundStep < 0) {
      if (shouldShow(nextStep)) {
        foundStep = nextStep;
      } else {
        skipStep(nextStep);
      }
      nextStep += 1;
    }
    const foundStepObject = steps[foundStep];
    // -- tracker.trackOnboardingStepView(nextStep)
    navigate(foundStepObject.path);
  };

  /*
  Multiple possible implementations for this, all with cons.
  Let's consider two use case :
  - Nominal case : user starts the workflow at the beginning and clicks previous on a step
  - Edge case : user types in the browser's URL bar an URL of a specific step (say step 3).

  Props & cons of implementations :
  - navigate(-1) will copy the browser's previous button.
    - Great for UX in nominal case, when patient starts from the beginning of the workflow
    - Syncs with the browser's history
    - Cons: Edge case : this implementation will just take you back outside of the worfklow instead of the actual previous step (step 2)
  - Find previous step index, and navigate to its path
    - Allows edge case user to access step 2
    - However if previous step (step 2) is a nested workflow, this will show the beginning of the workflow instead of it's last page

  Meaning :
  - Edge case should probably not be supported, as we want users to complete each step of the workflow at least one.
  - Workflow should probably have a state, indicating if it was completed, and allowing user to bypass.
  - Workflow should probably force taking steps previous to the one actually requested, unless some user data is fetched and vouch for the user, allowing him to bypass
  */
  const anyGoPrevious = (): void => {
    navigate(-1);
  };

  const complete = (): void => {
    const { complete } = props;
    complete && complete();
  };

  const goBack = (): void => {
    const { goBack } = props;
    goBack && goBack();
  };

  const { steps, stepperStyle, level } = props;
  const currentIndex = findCurrentStep();
  const percent = isEmpty(steps)
    ? 0
    : ((currentIndex + 1) / steps.length) * 100;
  const stepRoutes = steps.map((step, index) => ({
    path: `${step.path}/*`,
    element: (
      <WorkflowContext.Provider
        value={{
          navigation: {
            goNext: goNext,
            goPrevious: goPrevious,
            complete: complete,
            goBack: goBack,
            navigate: navigate,
            currentStep: currentIndex,
            stepIndex: index,
            steps: steps,
            stepperStyle: stepperStyle || 'none',
          },
        }}
      >
        {step.comp}
      </WorkflowContext.Provider>
    ),
  }));
  const redirectRoute = {
    path: '*',
    element: <Navigate to={steps[0].path} replace />,
  };
  const routes = [...stepRoutes, redirectRoute];
  const debug = false;
  return (
    <>
      {stepperStyle === 'progressionBar' && (
        <div className="sub-step-stepper" style={{ width: `${percent}%` }} />
      )}
      {debug && (
        <div>
          {'Workflow '}
          {level} {steps.map((step) => step.path).join('->')}
        </div>
      )}
      {useRoutes(routes)}
    </>
  );
};

export const NestedWorkflow = (props: T.NestedWorklowProps) => {
  const workflowNavigation = useWorkflowNavigation().navigation;
  return (
    <Workflow
      steps={props.steps}
      stepperStyle={props.stepperStyle}
      complete={props.complete ?? workflowNavigation.goNext}
      goBack={props.goBack ?? workflowNavigation.goPrevious}
    />
  );
};

export default Workflow;
