import { I18nProvider as LinguiI18nProvider } from '@lingui/react';
import { i18n } from './I18n.singleton';

type I18nProviderProps = {
  children: React.ReactNode;
};

export const I18nProvider = ({ children }: I18nProviderProps) => {
  return <LinguiI18nProvider i18n={i18n}>{children}</LinguiI18nProvider>;
};
