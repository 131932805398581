import { t } from '@lingui/macro';
import { useMemo } from 'react';
import { useWorkflowNavigation } from '../../../shared/view/components/Workflow';
import { useStageLoadingTimer } from '../../Enrollment/domain/useStageLoadingTimer';
import { useGetEnrollment } from '../../Enrollment/infra/useGetEnrollment';
import { BackgroundCircles } from '../shared/view/BackgroundCircles';
import { CentralProgessBar } from '../shared/view/ProgressBar';
import { ScreenContentContainer } from '../shared/view/ScreenContentContainer';

export const WeightLoaderScreen = () => {
  useGetEnrollment();
  const { navigation } = useWorkflowNavigation();

  const stageLoadingDetails = useMemo(
    () => ({
      initialLabel: t`weight.calculation.loading.1`,
      totalDuration: 7,
      tick: 0.05,
      stages: [
        {
          label: t`weight.calculation.loading.2`,
          second: 2,
          value: 4,
        },
        {
          label: t`weight.calculation.loading.3`,
          second: 4,
          value: 7,
        },
      ],
      onComplete: () => navigation.goNext(),
    }),
    [navigation],
  );

  const { loadingProgress, loadingLabel, maxProgress } =
    useStageLoadingTimer(stageLoadingDetails);

  return (
    <ScreenContentContainer>
      <div />
      <div className="flex flex-col items-center w-full">
        <CentralProgessBar
          label={loadingLabel}
          currentStep={loadingProgress}
          maxStep={maxProgress}
        />
      </div>
      <div />
      <BackgroundCircles />
    </ScreenContentContainer>
  );
};
