import { includes, isEmpty } from 'lodash';

export const TEST_CARDS = [
  '4706750000000009',
  '4706750000000033',
  '4706750000000025',
  '4706750000000017',
  '3569990000000132',
  '3569990000000157',
  '4970100000000154',
  '4970101122334422',
  '4970101122334406',
  '4970101122334414',
  '3012340000000000',
  '3012349999999999',
];

export const isCreditCardNumberValid = (value: string): boolean => {
  if (isEmpty(value)) {
    return false;
  }

  const visaPattern = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  const mastPattern = /^(?:5[1-5][0-9]{14})$/;
  const amexPattern = /^(?:3[47][0-9]{13})$/;
  const discPattern = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
  const maestroPattern = /^(5[06-8]|6\d)\d{10,17}$/;
  const rawValue = value.replace(/\D+/g, '');

  if (
    visaPattern.test(rawValue) ||
    mastPattern.test(rawValue) ||
    amexPattern.test(rawValue) ||
    discPattern.test(rawValue) ||
    maestroPattern.test(rawValue) ||
    includes(TEST_CARDS, rawValue)
  ) {
    return true;
  } else {
    return false;
  }
};

export const isCreditCardHolderNameValid = (value: string): boolean => {
  if (isEmpty(value)) {
    return false;
  }

  const regexName = /^[\w\W]*\s[\w\W]*$/g;
  const wordsCount = value.split(' ').filter((word) => word).length;
  return regexName.test(value) && wordsCount >= 2;
};

export const isCreditExpiryDateValid = (value: string): boolean => {
  if (isEmpty(value)) {
    return false;
  }

  const month = parseInt(value.substring(0, 2));
  if (month <= 0 && month > 12) {
    return false;
  }
  const year = parseInt(`20${value.slice(-2)}`, 10);
  const expiry = new Date(year, month, 0, 0, 0, 0, 0);

  return expiry > new Date();
};

export const isCreditCardMonthValid = (value: string): boolean => {
  const month = parseInt(value);
  return month >= 0 && month <= 12;
};

export const isCreditCardYearValid = (value: string): boolean => {
  const year = parseInt(value);
  return year >= 0 && year <= 99;
};

export const isCreditCardCVVValid = (value: string): boolean => {
  const regex = /^\d{3}$/g;
  return regex.test(value);
};

export const isCreditCardConditionValid = (checked: boolean): boolean => {
  return checked;
};
