import { memo } from 'react';

const AppleSvg = () => {
  return (
    <svg
      width="136"
      height="40"
      viewBox="0 0 136 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3069_14654)">
        <path
          d="M125.331 0.00013H10.6747C10.2834 0.00013 9.89681 0.00013 9.50653 0.00213C9.17982 0.00413 8.85573 0.00994 8.52589 0.01483C7.80932 0.0227318 7.09445 0.0818063 6.38746 0.19154C5.68148 0.303663 4.9976 0.515046 4.35898 0.81854C3.72113 1.1246 3.13831 1.5223 2.63167 1.9972C2.12236 2.47077 1.69781 3.01815 1.37435 3.61829C1.05 4.21724 0.825091 4.85907 0.707397 5.52161C0.588583 6.18332 0.524649 6.85265 0.516167 7.52361C0.506264 7.83021 0.505218 8.13783 0.5 8.44447V31.5587C0.505218 31.8692 0.506264 32.17 0.516167 32.4806C0.524652 33.1516 0.588586 33.8209 0.707397 34.4825C0.824766 35.1455 1.04969 35.7877 1.37435 36.3868C1.69767 36.985 2.12227 37.5302 2.63167 38.0011C3.13639 38.4781 3.71959 38.8761 4.35898 39.1798C4.9976 39.4841 5.6814 39.6968 6.38746 39.8106C7.09457 39.9195 7.80937 39.9786 8.52589 39.9874C8.85573 39.9942 9.17982 39.9981 9.50653 39.9981C9.8968 40.0001 10.2835 40.0001 10.6747 40.0001H125.331C125.715 40.0001 126.104 40.0001 126.488 39.9981C126.813 39.9981 127.146 39.9942 127.472 39.9874C128.187 39.9791 128.9 39.92 129.606 39.8106C130.314 39.696 131 39.4834 131.642 39.1798C132.281 38.8759 132.864 38.478 133.368 38.0011C133.876 37.5284 134.301 36.9836 134.629 36.3868C134.951 35.7872 135.174 35.1451 135.289 34.4825C135.408 33.8208 135.475 33.1516 135.488 32.4806C135.492 32.17 135.492 31.8692 135.492 31.5587C135.5 31.1954 135.5 30.8341 135.5 30.4649V9.53626C135.5 9.17005 135.5 8.80677 135.492 8.44447C135.492 8.13783 135.492 7.83021 135.488 7.52357C135.475 6.85255 135.408 6.18337 135.289 5.52157C135.173 4.85941 134.951 4.21763 134.629 3.61825C133.97 2.41533 132.926 1.43616 131.642 0.81845C131 0.515697 130.314 0.30437 129.606 0.19145C128.9 0.0812328 128.187 0.0221378 127.472 0.01469C127.146 0.00981 126.813 0.00395 126.488 0.002C126.104 0 125.715 0.00013 125.331 0.00013Z"
          fill="#313232"
        />
        <path
          d="M9.51175 39.125C9.18663 39.125 8.86934 39.1211 8.54676 39.1143C7.87851 39.1061 7.21181 39.0516 6.55215 38.9512C5.93704 38.8519 5.34117 38.6673 4.78419 38.4033C4.23232 38.1415 3.72896 37.7983 3.29341 37.3867C2.85155 36.98 2.48369 36.5082 2.20439 35.9902C1.92202 35.4688 1.7266 34.9099 1.62494 34.333C1.51516 33.7131 1.45576 33.0863 1.44726 32.458C1.4405 32.2471 1.43164 31.5449 1.43164 31.5449V8.44434C1.43164 8.44434 1.44107 7.75293 1.44732 7.5498C1.45546 6.92248 1.5145 6.29665 1.62395 5.67773C1.7258 5.09925 1.92138 4.53875 2.2039 4.01563C2.48217 3.49795 2.84799 3.02586 3.28718 2.61768C3.72588 2.20562 4.23085 1.86061 4.78367 1.59521C5.33937 1.33209 5.93411 1.14873 6.54798 1.05127C7.20981 0.949836 7.87882 0.894996 8.54939 0.88721L9.51228 0.875H126.483L127.457 0.8877C128.121 0.895099 128.784 0.94945 129.44 1.05029C130.06 1.14898 130.661 1.33362 131.223 1.59814C132.331 2.13299 133.232 2.97916 133.8 4.01807C134.078 4.53758 134.271 5.09351 134.371 5.66699C134.482 6.29099 134.544 6.92174 134.557 7.5542C134.56 7.8374 134.56 8.1416 134.56 8.44434C134.568 8.81934 134.568 9.17627 134.568 9.53613V30.4648C134.568 30.8281 134.568 31.1826 134.56 31.54C134.56 31.8652 134.56 32.1631 134.556 32.4697C134.543 33.0909 134.482 33.7104 134.373 34.3232C134.274 34.9043 134.08 35.4675 133.797 35.9932C133.516 36.5056 133.15 36.9733 132.713 37.3789C132.277 37.7927 131.773 38.1379 131.22 38.4014C130.66 38.6674 130.06 38.8527 129.44 38.9512C128.781 39.0522 128.114 39.1067 127.445 39.1143C127.133 39.1211 126.806 39.125 126.488 39.125L125.331 39.127L9.51175 39.125Z"
          fill="black"
        />
        <path
          d="M26.9347 20.3013C26.9462 19.4666 27.1828 18.6483 27.6225 17.9223C28.0621 17.1964 28.6906 16.5864 29.4495 16.1492C28.9674 15.5041 28.3314 14.9731 27.5921 14.5986C26.8528 14.224 26.0304 14.0162 25.1904 13.9916C23.3984 13.8153 21.6612 14.9964 20.748 14.9964C19.8172 14.9964 18.4112 14.0091 16.8973 14.0383C15.918 14.0679 14.9636 14.3348 14.1271 14.8128C13.2906 15.2909 12.6005 15.9638 12.1241 16.7661C10.0603 20.1145 11.5997 25.0356 13.5767 27.7422C14.5658 29.0676 15.7218 30.548 17.2344 30.4955C18.7146 30.438 19.2674 29.611 21.0541 29.611C22.8242 29.611 23.3429 30.4955 24.8861 30.4621C26.4744 30.438 27.4751 29.1309 28.4296 27.793C29.1402 26.8486 29.6871 25.8049 30.0499 24.7005C29.1271 24.3347 28.3397 23.7226 27.7857 22.9403C27.2317 22.158 26.9358 21.2402 26.9347 20.3013Z"
          fill="white"
        />
        <path
          d="M24.0197 12.2113C24.8857 11.2371 25.3123 9.98494 25.209 8.7207C23.886 8.85092 22.6638 9.44348 21.7861 10.3803C21.357 10.838 21.0283 11.3704 20.8189 11.9472C20.6095 12.524 20.5234 13.1337 20.5656 13.7417C21.2274 13.7481 21.8821 13.6137 22.4804 13.3486C23.0787 13.0835 23.605 12.6946 24.0197 12.2113Z"
          fill="white"
        />
        <path
          d="M38.5489 14.7012V9.57764H36.5459V8.73438H41.5366V9.57763H39.5378V14.7012H38.5489Z"
          fill="white"
        />
        <path
          d="M46.1354 13.4858C46.0065 13.8974 45.7273 14.2534 45.3467 14.4915C44.9661 14.7297 44.5082 14.8348 44.0532 14.7886C43.7366 14.7964 43.422 14.7396 43.1312 14.622C42.8404 14.5045 42.5804 14.3291 42.3692 14.108C42.158 13.8868 42.0007 13.6253 41.9082 13.3414C41.8157 13.0576 41.7902 12.7583 41.8335 12.4643C41.7913 12.1695 41.8173 11.8695 41.9096 11.5849C42.0019 11.3002 42.1583 11.0374 42.3684 10.8143C42.5785 10.5913 42.8373 10.4131 43.1272 10.2919C43.4172 10.1707 43.7315 10.1093 44.049 10.1118C45.3861 10.1118 46.1927 10.9678 46.1927 12.3818V12.6919H42.7995V12.7417C42.7847 12.9069 42.8068 13.0732 42.8646 13.23C42.9224 13.3867 43.0144 13.5305 43.1349 13.6519C43.2554 13.7734 43.4016 13.8699 43.5643 13.9353C43.7269 14.0007 43.9023 14.0335 44.0792 14.0317C44.3061 14.0572 44.5359 14.0189 44.7395 13.9217C44.943 13.8245 45.1112 13.6728 45.2225 13.4858L46.1354 13.4858ZM42.7995 12.0347H45.2266C45.2386 11.8835 45.2167 11.7317 45.1623 11.589C45.1079 11.4463 45.0223 11.3158 44.9109 11.206C44.7995 11.0961 44.6648 11.0093 44.5155 10.9511C44.3662 10.893 44.2055 10.8647 44.0438 10.8682C43.8798 10.8662 43.717 10.8951 43.5651 10.953C43.4132 11.011 43.2751 11.0968 43.1592 11.2055C43.0432 11.3143 42.9517 11.4436 42.8899 11.586C42.8281 11.7284 42.7974 11.881 42.7995 12.0347ZM43.479 9.4458L44.5868 8.02344H45.6987L44.4586 9.4458H43.479Z"
          fill="white"
        />
        <path
          d="M47.5103 8.44043H48.4586V14.7012H47.5103V8.44043Z"
          fill="white"
        />
        <path
          d="M54.0787 13.4858C53.9499 13.8974 53.6707 14.2534 53.2901 14.4915C52.9094 14.7297 52.4516 14.8348 51.9966 14.7886C51.68 14.7964 51.3654 14.7396 51.0746 14.622C50.7838 14.5045 50.5238 14.3291 50.3126 14.108C50.1014 13.8868 49.9441 13.6253 49.8515 13.3414C49.759 13.0576 49.7335 12.7583 49.7768 12.4643C49.7347 12.1695 49.7606 11.8695 49.8529 11.5849C49.9452 11.3002 50.1017 11.0374 50.3118 10.8143C50.5219 10.5913 50.7806 10.4131 51.0706 10.2919C51.3605 10.1707 51.6749 10.1093 51.9924 10.1118C53.3294 10.1118 54.136 10.9678 54.136 12.3818V12.6919H50.7429V12.7417C50.728 12.9069 50.7502 13.0732 50.8079 13.23C50.8657 13.3867 50.9578 13.5305 51.0782 13.6519C51.1987 13.7734 51.345 13.8699 51.5076 13.9353C51.6702 14.0007 51.8456 14.0335 52.0226 14.0317C52.2494 14.0572 52.4792 14.0189 52.6828 13.9217C52.8864 13.8245 53.0545 13.6728 53.1658 13.4858L54.0787 13.4858ZM50.7429 12.0347H53.17C53.1819 11.8835 53.16 11.7317 53.1056 11.589C53.0512 11.4463 52.9656 11.3158 52.8542 11.206C52.7428 11.0961 52.6081 11.0093 52.4588 10.9511C52.3095 10.893 52.1488 10.8647 51.9871 10.8682C51.8231 10.8662 51.6604 10.8951 51.5084 10.953C51.3565 11.011 51.2185 11.0968 51.1025 11.2055C50.9866 11.3143 50.895 11.4436 50.8332 11.586C50.7715 11.7284 50.7407 11.881 50.7429 12.0347ZM51.4223 9.4458L52.5301 8.02344H53.6421L52.402 9.4458H51.4223Z"
          fill="white"
        />
        <path
          d="M58.5554 11.6705C58.495 11.437 58.3473 11.2316 58.1391 11.0913C57.9309 10.951 57.676 10.8853 57.4206 10.9059C56.6265 10.9059 56.1408 11.4762 56.1408 12.4357C56.1408 13.4118 56.6307 13.9948 57.4206 13.9948C57.6735 14.0222 57.9284 13.9622 58.1374 13.8258C58.3464 13.6895 58.4951 13.4864 58.5554 13.2545H59.4777C59.4167 13.7105 59.1681 14.1261 58.7847 14.4127C58.4014 14.6993 57.9138 14.8342 57.4258 14.7887C57.1045 14.7982 56.7848 14.7418 56.4892 14.6235C56.1936 14.5052 55.9292 14.3277 55.7144 14.1036C55.4996 13.8795 55.3396 13.6141 55.2457 13.326C55.1518 13.0379 55.1262 12.7341 55.1706 12.4357C55.1284 12.1396 55.1556 11.8384 55.2505 11.5531C55.3453 11.2679 55.5054 11.0054 55.7197 10.7842C55.9339 10.5629 56.1971 10.3881 56.4909 10.272C56.7847 10.1559 57.1019 10.1013 57.4206 10.112C57.9125 10.0695 58.4028 10.2086 58.787 10.4997C59.1712 10.7908 59.419 11.2109 59.4777 11.6706L58.5554 11.6705Z"
          fill="white"
        />
        <path
          d="M60.7369 8.44043H61.6769V10.9219H61.7519C61.878 10.6626 62.0863 10.446 62.3485 10.3016C62.6107 10.1573 62.914 10.0922 63.2171 10.1152C63.4497 10.1034 63.6822 10.1398 63.898 10.2218C64.1139 10.3039 64.3078 10.4295 64.4658 10.5899C64.6239 10.7502 64.7422 10.9413 64.8124 11.1494C64.8826 11.3575 64.903 11.5776 64.8719 11.794V14.7012H63.9226V12.0132C63.9226 11.2939 63.5651 10.9297 62.895 10.9297C62.732 10.9172 62.5681 10.9382 62.4147 10.9912C62.2612 11.0443 62.122 11.1281 62.0068 11.2368C61.8916 11.3456 61.8031 11.4766 61.7475 11.6207C61.6919 11.7649 61.6707 11.9186 61.6851 12.0713V14.7012H60.7368L60.7369 8.44043Z"
          fill="white"
        />
        <path
          d="M66.064 13.4287C66.064 12.6182 66.708 12.1509 67.8512 12.0845L69.1528 12.0142V11.6255C69.1528 11.1499 68.8172 10.8813 68.169 10.8813C67.6396 10.8813 67.2728 11.0635 67.1676 11.3818H66.2495C66.3464 10.6084 67.1228 10.1123 68.2128 10.1123C69.4175 10.1123 70.0964 10.6743 70.0964 11.6255V14.7021H69.184V14.0693H69.1091C68.9568 14.2963 68.7429 14.4814 68.4893 14.6056C68.2358 14.7298 67.9516 14.7887 67.6657 14.7763C67.464 14.796 67.2601 14.7758 67.0672 14.7171C66.8743 14.6584 66.6967 14.5625 66.5458 14.4355C66.3949 14.3086 66.274 14.1534 66.191 13.98C66.108 13.8065 66.0648 13.6188 66.064 13.4287ZM69.1528 13.0439V12.6675L67.9794 12.7378C67.3176 12.7793 67.0175 12.9902 67.0175 13.3872C67.0175 13.7925 67.3927 14.0283 67.9086 14.0283C68.0597 14.0427 68.2124 14.0284 68.3575 13.9863C68.5027 13.9442 68.6373 13.8752 68.7534 13.7833C68.8695 13.6915 68.9647 13.5787 69.0333 13.4517C69.1019 13.3246 69.1425 13.186 69.1528 13.0439Z"
          fill="white"
        />
        <path
          d="M71.5815 10.1986H72.4944V10.889H72.5653C72.6675 10.6493 72.8481 10.4462 73.0812 10.3087C73.3143 10.1712 73.5879 10.1065 73.8627 10.1239C74.0048 10.1221 74.1466 10.1345 74.2858 10.161V11.038C74.1118 11.0031 73.9344 10.9851 73.7564 10.9843C73.6 10.9711 73.4424 10.9887 73.2937 11.0361C73.145 11.0834 73.0085 11.1593 72.893 11.259C72.7775 11.3587 72.6855 11.4799 72.623 11.6149C72.5604 11.7499 72.5287 11.8957 72.5299 12.0428V14.7015H71.5816L71.5815 10.1986Z"
          fill="white"
        />
        <path
          d="M75.1579 15.1523H76.1281C76.2084 15.479 76.6096 15.6899 77.2495 15.6899C78.0394 15.6899 78.5073 15.3384 78.5073 14.7431V13.8789H78.4364C78.2877 14.1208 78.0705 14.3195 77.8088 14.453C77.547 14.5864 77.2511 14.6495 76.9535 14.6352C75.7269 14.6352 74.9683 13.7466 74.9683 12.3984C74.9683 11.0254 75.7353 10.124 76.9629 10.124C77.2679 10.114 77.57 10.1828 77.8361 10.3228C78.1022 10.4629 78.3221 10.6687 78.4719 10.9179H78.5469V10.1987H79.4556V14.7387C79.4556 15.7685 78.5948 16.4218 77.2359 16.4218C76.0354 16.4219 75.259 15.9175 75.1579 15.1523ZM78.525 12.394C78.525 11.497 78.03 10.9223 77.2224 10.9223C76.4064 10.9223 75.9479 11.497 75.9479 12.394C75.9479 13.2915 76.4064 13.8662 77.2224 13.8662C78.0352 13.8662 78.525 13.2959 78.525 12.394Z"
          fill="white"
        />
        <path
          d="M85.0299 13.4854C84.901 13.897 84.6219 14.253 84.2412 14.4911C83.8606 14.7293 83.4027 14.8344 82.9477 14.7882C82.6311 14.796 82.3165 14.7392 82.0258 14.6216C81.735 14.5041 81.4749 14.3287 81.2637 14.1076C81.0525 13.8864 80.8952 13.6249 80.8027 13.341C80.7102 13.0572 80.6847 12.7579 80.728 12.4639C80.6859 12.1691 80.7118 11.8691 80.8041 11.5845C80.8964 11.2998 81.0529 11.037 81.263 10.8139C81.473 10.5909 81.7318 10.4127 82.0218 10.2915C82.3117 10.1703 82.6261 10.1089 82.9436 10.1114C84.2806 10.1114 85.0872 10.9674 85.0872 12.3814V12.6915H81.6941V12.7413C81.6792 12.9065 81.7014 13.0728 81.7591 13.2296C81.8169 13.3863 81.909 13.53 82.0295 13.6515C82.1499 13.773 82.2962 13.8695 82.4588 13.9349C82.6214 14.0003 82.7968 14.0331 82.9738 14.0313C83.2006 14.0568 83.4305 14.0185 83.634 13.9213C83.8376 13.8241 84.0057 13.6723 84.117 13.4854L85.0299 13.4854ZM81.694 12.0343H84.1211C84.1331 11.8831 84.1112 11.7313 84.0568 11.5886C84.0024 11.4459 83.9168 11.3154 83.8054 11.2056C83.694 11.0957 83.5593 11.0089 83.41 10.9507C83.2606 10.8925 83.1 10.8643 82.9383 10.8678C82.7743 10.8658 82.6115 10.8947 82.4596 10.9526C82.3077 11.0106 82.1697 11.0964 82.0537 11.2052C81.9378 11.3139 81.8462 11.4432 81.7844 11.5856C81.7227 11.728 81.6919 11.8806 81.694 12.0343Z"
          fill="white"
        />
        <path
          d="M86.3608 10.1986H87.2738V10.889H87.3447C87.4469 10.6493 87.6275 10.4462 87.8606 10.3087C88.0937 10.1712 88.3673 10.1065 88.6421 10.1239C88.7841 10.1221 88.926 10.1345 89.0652 10.161V11.038C88.8912 11.0031 88.7138 10.9851 88.5358 10.9843C88.3794 10.9711 88.2218 10.9887 88.0731 11.036C87.9243 11.0834 87.7878 11.1593 87.6723 11.259C87.5568 11.3587 87.4648 11.4799 87.4023 11.6149C87.3397 11.7499 87.308 11.8957 87.3092 12.0428V14.7015H86.3608V10.1986Z"
          fill="white"
        />
        <path
          d="M92.4824 12.4478C92.4824 11.0249 93.263 10.1235 94.477 10.1235C94.7773 10.1106 95.0754 10.178 95.3363 10.3179C95.5973 10.4578 95.8103 10.6644 95.9506 10.9135H96.0215V8.44043H96.9698V14.7012H96.0611V13.9897H95.986C95.8349 14.2372 95.6144 14.4413 95.3484 14.5797C95.0824 14.7182 94.7811 14.7859 94.477 14.7754C93.2546 14.7754 92.4824 13.874 92.4824 12.4478ZM93.462 12.4478C93.462 13.4028 93.9425 13.9775 94.7459 13.9775C95.5452 13.9775 96.0392 13.3945 96.0392 12.4517C96.0392 11.5132 95.54 10.9219 94.7459 10.9219C93.9476 10.9219 93.462 11.5005 93.462 12.4478Z"
          fill="white"
        />
        <path
          d="M98.2515 13.4287C98.2515 12.6182 98.8955 12.1509 100.039 12.0845L101.34 12.0142V11.6255C101.34 11.1499 101.005 10.8813 100.357 10.8813C99.8272 10.8813 99.4603 11.0635 99.3551 11.3818H98.437C98.5339 10.6084 99.3103 10.1123 100.4 10.1123C101.605 10.1123 102.284 10.6743 102.284 11.6255V14.7021H101.372V14.0693H101.297C101.144 14.2963 100.93 14.4814 100.677 14.6056C100.423 14.7298 100.139 14.7887 99.8532 14.7763C99.6515 14.796 99.4476 14.7758 99.2547 14.7171C99.0618 14.6584 98.8842 14.5625 98.7333 14.4355C98.5824 14.3086 98.4615 14.1534 98.3785 13.98C98.2955 13.8065 98.2523 13.6188 98.2515 13.4287ZM101.34 13.0439V12.6675L100.167 12.7378C99.5052 12.7793 99.205 12.9902 99.205 13.3872C99.205 13.7925 99.5802 14.0283 100.096 14.0283C100.247 14.0426 100.4 14.0283 100.545 13.9862C100.69 13.9442 100.825 13.8751 100.941 13.7833C101.057 13.6915 101.152 13.5787 101.221 13.4517C101.289 13.3246 101.33 13.186 101.34 13.0439Z"
          fill="white"
        />
        <path
          d="M103.77 10.1988H104.682V10.9141H104.753C104.874 10.6572 105.076 10.4418 105.333 10.298C105.59 10.1542 105.889 10.0891 106.187 10.1119C106.421 10.0954 106.656 10.1284 106.874 10.2085C107.092 10.2886 107.289 10.4137 107.449 10.5746C107.608 10.7354 107.727 10.9279 107.797 11.1377C107.866 11.3475 107.885 11.5693 107.85 11.7867V14.7017H106.902V12.0098C106.902 11.2862 106.567 10.9263 105.865 10.9263C105.706 10.9194 105.548 10.9447 105.401 11.0005C105.253 11.0564 105.121 11.1414 105.012 11.2498C104.903 11.3582 104.82 11.4873 104.769 11.6285C104.719 11.7696 104.701 11.9193 104.718 12.0674V14.7017H103.77V10.1988Z"
          fill="white"
        />
        <path
          d="M111.073 10.1123C112.154 10.1123 112.861 10.5835 112.953 11.3774H112.044C111.956 11.0469 111.611 10.8359 111.073 10.8359C110.544 10.8359 110.142 11.0713 110.142 11.4229C110.142 11.6919 110.385 11.8613 110.906 11.9731L111.704 12.1465C112.618 12.3452 113.046 12.7134 113.046 13.375C113.046 14.2227 112.203 14.7891 111.056 14.7891C109.913 14.7891 109.167 14.3052 109.083 13.5073H110.032C110.114 13.6972 110.262 13.8559 110.451 13.9578C110.641 14.0597 110.861 14.099 111.078 14.0693C111.669 14.0693 112.088 13.8213 112.088 13.4614C112.088 13.1929 111.863 13.019 111.382 12.9116L110.544 12.7295C109.63 12.5268 109.207 12.1426 109.207 11.4726C109.207 10.6743 109.988 10.1123 111.073 10.1123Z"
          fill="white"
        />
        <path
          d="M38.061 18.0664H40.0452V30.4849H38.061V18.0664Z"
          fill="white"
        />
        <path
          d="M42.436 22.6104L43.5198 18.0664H45.4478L44.1347 22.6104H42.436Z"
          fill="white"
        />
        <path
          d="M52.9456 27.1284H47.8945L46.6815 30.4849H44.542L49.3264 18.0664H51.5492L56.3336 30.4849H54.1576L52.9456 27.1284ZM48.4176 25.5796H52.4215L50.4477 20.1318H50.3925L48.4176 25.5796Z"
          fill="white"
        />
        <path
          d="M66.6653 25.9582C66.6653 28.7717 65.0584 30.5793 62.6334 30.5793C62.0191 30.6094 61.4081 30.4768 60.8705 30.1967C60.3329 29.9166 59.8903 29.5002 59.5935 28.9953H59.5476V33.4797H57.5645V21.4304H59.484V22.9362H59.5205C59.831 22.4338 60.281 22.0196 60.8227 21.7377C61.3645 21.4559 61.9776 21.3169 62.5968 21.3357C65.049 21.3357 66.6653 23.1521 66.6653 25.9582ZM64.6269 25.9582C64.6269 24.1247 63.6161 22.9197 62.0737 22.9197C60.5585 22.9197 59.5393 24.1501 59.5393 25.9582C59.5393 27.7825 60.5585 29.0041 62.0737 29.0041C63.6161 29.0041 64.6269 27.8078 64.6269 25.9582Z"
          fill="white"
        />
        <path
          d="M77.2986 25.9582C77.2986 28.7717 75.6916 30.5793 73.2666 30.5793C72.6523 30.6094 72.0414 30.4768 71.5038 30.1967C70.9661 29.9166 70.5236 29.5002 70.2267 28.9953H70.1809V33.4797H68.1982V21.4304H70.1173V22.9362H70.1538C70.4643 22.4338 70.9143 22.0196 71.456 21.7377C71.9977 21.4559 72.6109 21.3169 73.2301 21.3357C75.6822 21.3357 77.2986 23.1521 77.2986 25.9582ZM75.2602 25.9582C75.2602 24.1247 74.2493 22.9197 72.707 22.9197C71.1917 22.9197 70.1725 24.1501 70.1725 25.9582C70.1725 27.7825 71.1917 29.0041 72.707 29.0041C74.2493 29.0041 75.2602 27.8078 75.2602 25.9582Z"
          fill="white"
        />
        <path
          d="M84.3265 27.0249C84.4734 28.2564 85.75 29.0649 87.4945 29.0649C89.1661 29.0649 90.3687 28.2563 90.3687 27.146C90.3687 26.1821 89.6433 25.605 87.9259 25.2094L86.2085 24.8213C83.7752 24.2705 82.6455 23.2041 82.6455 21.4736C82.6455 19.3311 84.638 17.8594 87.4674 17.8594C90.2675 17.8594 92.1871 19.3311 92.2517 21.4736H90.2498C90.13 20.2344 89.0368 19.4863 87.4392 19.4863C85.8417 19.4863 84.7485 20.2432 84.7485 21.3447C84.7485 22.2227 85.4467 22.7393 87.1548 23.1347L88.6148 23.4707C91.3336 24.0732 92.4633 25.0971 92.4633 26.9135C92.4633 29.2368 90.4885 30.6919 87.3475 30.6919C84.4088 30.6919 82.4246 29.271 82.2964 27.0249L84.3265 27.0249Z"
          fill="white"
        />
        <path
          d="M96.7445 19.2881V21.4307H98.5818V22.9023H96.7445V27.894C96.7445 28.6694 97.1124 29.0308 97.92 29.0308C98.1381 29.0272 98.3559 29.0128 98.5724 28.9878V30.4507C98.2093 30.5142 97.8402 30.543 97.4709 30.5366C95.5148 30.5366 94.752 29.8481 94.752 28.0923V22.9023H93.3472V21.4307H94.7519V19.2881H96.7445Z"
          fill="white"
        />
        <path
          d="M99.6445 25.9585C99.6445 23.1094 101.435 21.3193 104.227 21.3193C107.028 21.3193 108.81 23.1093 108.81 25.9585C108.81 28.8149 107.037 30.5972 104.227 30.5972C101.417 30.5972 99.6445 28.8149 99.6445 25.9585ZM106.789 25.9585C106.789 24.0039 105.834 22.8506 104.227 22.8506C102.62 22.8506 101.665 24.0127 101.665 25.9585C101.665 27.9204 102.62 29.0649 104.227 29.0649C105.834 29.0649 106.789 27.9204 106.789 25.9585Z"
          fill="white"
        />
        <path
          d="M110.445 21.4303H112.337V22.9713H112.383C112.511 22.49 112.812 22.0647 113.235 21.7668C113.658 21.4689 114.178 21.3166 114.707 21.3356C114.935 21.3348 115.163 21.3581 115.386 21.4049V23.1431C115.098 23.0605 114.797 23.0226 114.495 23.0308C114.207 23.0199 113.92 23.0675 113.653 23.1705C113.386 23.2734 113.147 23.4292 112.95 23.6271C112.754 23.8251 112.606 24.0605 112.516 24.3172C112.426 24.5739 112.396 24.8459 112.428 25.1143V30.4845H110.445L110.445 21.4303Z"
          fill="white"
        />
        <path
          d="M124.528 27.8257C124.261 29.4692 122.553 30.5972 120.368 30.5972C117.557 30.5972 115.813 28.8325 115.813 26.0015C115.813 23.1611 117.567 21.3193 120.285 21.3193C122.958 21.3193 124.639 23.04 124.639 25.7856V26.4224H117.815V26.5347C117.783 26.8679 117.828 27.2035 117.945 27.5194C118.063 27.8352 118.251 28.124 118.497 28.3665C118.743 28.6091 119.041 28.7999 119.371 28.9262C119.702 29.0525 120.057 29.1114 120.414 29.0991C120.883 29.1403 121.353 29.0386 121.755 28.8091C122.157 28.5797 122.469 28.2348 122.645 27.8257L124.528 27.8257ZM117.824 25.1235H122.655C122.672 24.8239 122.624 24.524 122.512 24.2428C122.401 23.9615 122.228 23.7049 122.006 23.4891C121.783 23.2733 121.515 23.1029 121.219 22.9887C120.923 22.8744 120.605 22.8187 120.285 22.8252C119.962 22.8234 119.642 22.8816 119.343 22.9963C119.044 23.1111 118.772 23.2802 118.543 23.4939C118.315 23.7076 118.133 23.9616 118.01 24.2413C117.887 24.521 117.823 24.8208 117.824 25.1235Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3069_14654">
          <rect
            width="135"
            height="40"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AppAppleSvg = memo(AppleSvg);
