import { LeftChevronIcon } from '../../../../shared/view/icons/LeftChevronIcon';

type Props = {
  onClick: () => void;
};

export const BackButton = ({ onClick }: Props) => {
  return (
    <button aria-label="back" onClick={onClick} className="p-24 -m-24">
      <LeftChevronIcon />
    </button>
  );
};
