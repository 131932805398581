import type { ReactNode } from 'react';

type TypographyProps = {
  children: ReactNode;
  className?: string;
};

const ParagraphFactory = ({ size }: { size: string }) => {
  const SizedParagraph = ({ children, className = '' }: TypographyProps) => {
    return (
      <span className={`font-sans ${size} text-grey-0 ${className}`}>
        {children}
      </span>
    );
  };

  return SizedParagraph;
};

type TitleProps = {
  children: ReactNode;
  className?: string;
  as?: 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

const Title = ({ children, className = '', as: As = 'h1' }: TitleProps) => {
  return (
    <As
      className={`font-sans-ext text-title-l text-grey-0 font-semibold leading-tight ${className}`}
    >
      {children}
    </As>
  );
};

export const Typography = {
  P1: ParagraphFactory({ size: 'text-p1' }),
  P2: ParagraphFactory({ size: 'text-p2' }),
  P3: ParagraphFactory({ size: 'text-p3' }),
  P4: ParagraphFactory({ size: 'text-p4' }),
  P5: ParagraphFactory({ size: 'text-p5' }),
  Title,
};
