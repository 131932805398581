import { t } from '@lingui/macro';
import { Typography } from '../../shared/view/components/Typography/Typography';
import { BackgroundCircles } from '../Onboarding/shared/view/BackgroundCircles';
import { ScreenContentContainer } from '../Onboarding/shared/view/ScreenContentContainer';

export const WelcomePostFreeTrialScreen = () => {
  return (
    <ScreenContentContainer>
      <div />
      <div className="flex flex-col items-center">
        <div className="h-16" />
        <Typography.Title className="text-center">
          {t`welcome.post-free-trial.title-ft`}
        </Typography.Title>
        <div className="h-32" />
        <Typography.P2 className="text-center text-grey-300">
          {t`welcome.post-free-trial.long-text-ft`}
        </Typography.P2>
        <div className="h-40" />
      </div>
      <div />
      <BackgroundCircles />
    </ScreenContentContainer>
  );
};
