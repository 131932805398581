import { memo } from 'react';

const WarningIconSvg: React.FC = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.3397 10.7136C46.6987 7.53997 53.3013 7.53998 58.6602 10.7136L78.8397 22.6639C84.1987 25.8375 87.5 31.7025 87.5 38.0497V61.9504C87.5 68.2975 84.1987 74.1626 78.8397 77.3362L58.6602 89.2865C53.3013 92.4601 46.6987 92.4601 41.3397 89.2865L21.1603 77.3362C15.8013 74.1626 12.5 68.2976 12.5 61.9504V38.0497C12.5 31.7025 15.8013 25.8375 21.1603 22.6639L41.3397 10.7136ZM45.8333 70.8334C45.8333 68.5322 47.6988 66.6667 50 66.6667C52.3012 66.6667 54.1667 68.5322 54.1667 70.8334C54.1667 73.1346 52.3012 75 50 75C47.6988 75 45.8333 73.1346 45.8333 70.8334ZM46.875 58.3334C46.875 60.0593 48.2741 61.4584 50 61.4584C51.7259 61.4584 53.125 60.0593 53.125 58.3334L53.125 29.1667C53.125 27.4408 51.7259 26.0417 50 26.0417C48.2741 26.0417 46.875 27.4408 46.875 29.1667L46.875 58.3334Z"
        fill="#FF8959"
      />
    </svg>
  );
};

export const WarningIcon = memo(WarningIconSvg);
WarningIcon.displayName = 'Warning';
