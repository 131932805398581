import { t } from '@lingui/macro';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { analytics } from '../../../app/Analytics/analytics.singleton';
import type { GetEnrollmentQuery } from '../../../shared/infra/queries.types';
import { useOnboardingStore } from '../../Onboarding/shared/domain/onboarding.store';
import { useStartSecureChallenge } from '../../SecureChallenge/domain/secureChallenge.store';
import { useAddToast } from '../../toaster/domain/toaster.store';
import type { Order } from './enrollment.store';
import { useEnrollmentStore } from './enrollment.store';
import type { SubmitCreditCardCallback } from './payment/payment.rest';
import { getOrder, submitCreditCard } from './payment/payment.rest';
import type { PaymentSourcePayload } from './payment/payment.types';

type Props = {
  onCompleted: SubmitCreditCardCallback;
  order?: NonNullable<
    NonNullable<GetEnrollmentQuery['enrollment']>['subscription']
  >['spreeOrder'];
};

export const useSubmitCreditCard = ({
  onCompleted,
  order: propsOrder,
}: Props) => {
  const storeOrder: Order | undefined = useEnrollmentStore(
    (state) => state.order,
  );
  const order = propsOrder || storeOrder;
  const [searchParams] = useSearchParams();
  const enableBogus = searchParams.get('bogus') === '1';

  const { protocolName } = useOnboardingStore(
    useCallback((state) => state, []),
  );
  const { addToast } = useAddToast();
  const [loading, setLoading] = useState(false);

  const onCompletedAdapter = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (orderResult: any, errorMessage?: string) => {
      if (orderResult.isSuccess()) {
        if (order) {
          analytics.track('credit_card_enrolled', {
            amount_paid: order.total,
            protocol: protocolName,
            free_trial: false,
          });
        }
        onCompleted(orderResult.success().data, null);
      } else {
        const error = errorMessage || orderResult.fail().summary;
        onCompleted(null, error);
      }
    },
    [onCompleted, order, protocolName],
  );

  const onSecureChallengeEnd = useCallback(
    async (_status?: boolean) => {
      if (order) {
        const result = await getOrder(order.token, order.number);
        setLoading(false);
        onCompletedAdapter(result, t`payment.failed-secure-challenge`);
      }
    },
    [setLoading, onCompletedAdapter, order],
  );

  const { startSecureChallenge } =
    useStartSecureChallenge(onSecureChallengeEnd);

  const onCheckoutComplete = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (orderResult: any) => {
      if (orderResult.isSuccess()) {
        const order = orderResult.success().data;
        const secureChallengeUrl =
          order.attributes.gateway_secure_mode_redirect_url;
        if (secureChallengeUrl) {
          startSecureChallenge(secureChallengeUrl);
        } else {
          setLoading(false);
          onCompletedAdapter(orderResult);
        }
      } else {
        setLoading(false);
        onCompletedAdapter(orderResult);
      }
    },
    [setLoading, onCompletedAdapter, startSecureChallenge],
  );

  const submitCreditCardTrigger = useCallback(
    (data: PaymentSourcePayload) => {
      if (order) {
        const bogusPaymentMethod = order.paymentMethods?.find(
          (paymentMethod) => paymentMethod.name === 'BogusPayment',
        );
        const selectedPaymentMethodId =
          enableBogus && bogusPaymentMethod
            ? bogusPaymentMethod.id
            : order.paymentMethodId;

        setLoading(true);
        submitCreditCard(
          order.token,
          {
            paymentMethodId: selectedPaymentMethodId,
            data,
          },
          onCheckoutComplete,
        );
      } else {
        addToast({
          id: 'submissionErrors',
          message: t`error.default`,
        });
      }
    },
    [setLoading, order, onCheckoutComplete, addToast, enableBogus],
  );

  return {
    submitCreditCard: submitCreditCardTrigger,
    loading,
  };
};
