import { t } from '@lingui/macro';
import { useCallback, useMemo } from 'react';
import { SurveyType } from '../../../shared/infra/queries.types';
import { ErrorPage } from '../../../shared/view/components/ErrorPage/ErrorPage';
import { LoadingView } from '../../../shared/view/components/LoadingView/LoadingView';
import { useOnboardingStore } from '../shared/domain/onboarding.store';
import {
  createSurveyModel,
  useGetSurveys,
} from '../shared/infra/useGetSurveys';
import SurveyPage from '../shared/view/SurveyPage';

export const MedicalSurveyScreen = () => {
  const { medicalSurveyAnswers, setOnboardingAnswers } = useOnboardingStore(
    useCallback(
      (store) => ({
        setOnboardingAnswers: store.setOnboardingAnswers,
        medicalSurveyAnswers: store.medicalSurveyAnswers,
      }),
      [],
    ),
  );

  const onSurveyComplete = useCallback(
    (data?: Record<string, string>) => {
      if (!data) {
        return null;
      }
      setOnboardingAnswers({
        medicalSurveyAnswers: data,
      });
      return null;
    },
    [setOnboardingAnswers],
  );

  const {
    surveyData: medicalSurveyData,
    loading,
    error,
    refetch,
  } = useGetSurveys(SurveyType.Medical);

  const medicalSurvey = useMemo(
    () => createSurveyModel(medicalSurveyData?.payload),
    [medicalSurveyData?.payload],
  );

  if (error || (!loading && !medicalSurveyData)) {
    return <ErrorPage error={error} onRetry={refetch} isRetrying={loading} />;
  }

  if (loading || medicalSurveyData === undefined) {
    return <LoadingView isLoading={loading} />;
  }

  if (medicalSurvey === null) {
    return <ErrorPage error={error} onRetry={refetch} isRetrying={loading} />;
  }

  return (
    <SurveyPage
      model={medicalSurvey}
      surveyLabel={t`medicalSurvey.progress-bar`}
      setSurveyObject={onSurveyComplete}
      defaultData={medicalSurveyAnswers}
    />
  );
};
