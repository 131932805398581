import { useNavigate } from 'react-router';
import { useEnrollmentStore } from '../../../modules/Enrollment/domain/enrollment.store';
import { usePrefetchProblems } from '../../../modules/Onboarding/Medical/infra/usePrefetchProblems';
import { usePrefetchProtocolSpecificData } from '../../../modules/Onboarding/shared/infra/usePrefetchProtocolSpecificData';
import {
  isInWebView,
  postCompleteOnboarding,
  postGoBackOnFirstPage,
} from '../../../shared/helpers/webview/utils';
import { NestedWorkflow } from '../../../shared/view/components/Workflow';

import { SignIn } from '../../../modules/Onboarding/Finalization/SignIn.screen';
import { MaintenanceMode } from '../../../modules/Onboarding/MaintenanceMode.screen';
import { EnrollmentInOnboarding } from './EnrollmentInOnboarding';
import MedicalV2 from './Medical/MedicalV2';
import { MethodV2 } from './Method/MethodV2';

export const OnboardingV2 = () => {
  // We prefetch the data we need in the Onboarding to avoid loading states later
  // All the data is stored in the cache and fetch are not done if cache is enough later
  usePrefetchProblems();
  usePrefetchProtocolSpecificData();
  const enrollmentId = useEnrollmentStore((state) => state.enrollmentId);

  const navigate = useNavigate();

  const handleGoBack = () =>
    isInWebView() ? postGoBackOnFirstPage() : navigate(-1);
  const completeOnboarding = () =>
    isInWebView() ? postCompleteOnboarding() : navigate('/merci');

  const shouldDisablePatientApplication =
    process.env.REACT_APP_DISABLE_ONBOARDING === '1';

  const preSignInSteps = [
    {
      path: 'medical',
      comp: <MedicalV2 />,
    },
    {
      path: 'userInfos',
      comp: <SignIn />,
    },
  ];

  const postSignInSteps = shouldDisablePatientApplication
    ? [
        {
          path: 'maintenance',
          comp: <MaintenanceMode />,
        },
      ]
    : [
        {
          path: 'method',
          comp: <MethodV2 />,
        },
        {
          path: 'enrollment',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          show: (_: any) => !!enrollmentId,
          comp: <EnrollmentInOnboarding />,
        },
      ];

  const steps = preSignInSteps.concat(postSignInSteps);

  return (
    <NestedWorkflow
      steps={steps}
      goBack={handleGoBack}
      complete={completeOnboarding}
    />
  );
};
