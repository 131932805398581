import { useCallback } from 'react';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export type SecureChallengeStore = {
  challengeUrl?: string;
  status?: boolean;
  startSecureChallenge: (url: string) => void;
  endSecureChallenge: (status: boolean) => void;
};

export const useSecureChallengeStore = create<SecureChallengeStore>()(
  subscribeWithSelector((set) => {
    return {
      challengeUrl: undefined,
      status: false,
      startSecureChallenge: (challengeUrl: string) =>
        set(() => ({
          challengeUrl,
          status: false,
        })),
      endSecureChallenge: (status: boolean) =>
        set(() => ({
          challengeUrl: undefined,
          status,
        })),
    };
  }),
);

type SecureChallengeCallback = (status?: boolean) => void;

// Rassembler dans le même fichier que le SecureChallenge store
export const useStartSecureChallenge = (
  onCompleted: SecureChallengeCallback,
) => {
  const startSecureChallenge = useSecureChallengeStore(
    (state) => state.startSecureChallenge,
  );

  const wrappedTrigger = useCallback(
    (url: string) => {
      startSecureChallenge(url);
      // TODO - When to unsub ?
      const _unsub = useSecureChallengeStore.subscribe(
        (state) => ({ challengeUrl: state.challengeUrl, status: state.status }),
        (newState, oldState) => {
          if (!newState.challengeUrl && oldState.challengeUrl) {
            onCompleted(newState.status);
          }
        },
      );
    },
    [startSecureChallenge, onCompleted],
  );

  return {
    startSecureChallenge: wrappedTrigger,
  };
};
