import { Button } from '../../../../shared/view/components/Button/Button';
import { useWorkflowNavigation } from '../../../../shared/view/components/Workflow';

export type NextButtonProps = {
  label: string;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onPress?: () => void;
};

export const NextButton = ({
  label,
  className = '',
  isDisabled = false,
  isLoading = false,
  onPress,
}: NextButtonProps) => {
  const { navigation } = useWorkflowNavigation();
  const onCtaPressWrapper = () => {
    if (onPress) {
      onPress();
    } else {
      navigation.goNext();
    }
  };

  return (
    <Button.Primary
      className={`w-full font-semibold ${className}`}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onCtaPressWrapper}
      label={label}
    />
  );
};
