import { useOnboardingStore } from '../domain/onboarding.store';

export const estimateWeightLoss = (bmi: number | null) => {
  let goalWeightLost = 0;

  if (bmi == null) {
    return null;
  } else if (bmi < 25) {
    goalWeightLost = 4;
  } else if (bmi >= 25 && bmi < 30) {
    goalWeightLost = 6;
  } else if (bmi >= 30 && bmi < 40) {
    goalWeightLost = 8;
  } else if (bmi >= 40) {
    goalWeightLost = 10;
  }
  return goalWeightLost;
};

export const computeBMI = (initialWeight: number, initialHeight: number) => {
  if (initialWeight === undefined || initialHeight === undefined) {
    return null;
  }
  const heightInMeter = initialHeight / 100;
  const bmi = initialWeight / (heightInMeter * heightInMeter);

  return bmi;
};

export const useWeightLossEstimation = () => {
  const medicalSurveyAnswers = useOnboardingStore(
    (store) => store.medicalSurveyAnswers,
  );

  if (!medicalSurveyAnswers) {
    return {
      initialHeight: 0,
      initialWeight: 0,
      bmi: null,
      weightLossEstimation: null,
    };
  }

  const initialWeight: number = parseFloat(
    parseFloat(medicalSurveyAnswers.weight).toFixed(1),
  );
  const initialHeight: number = parseFloat(medicalSurveyAnswers.height);
  const bmi: number | null = computeBMI(initialWeight, initialHeight);
  const weightLossEstimation = estimateWeightLoss(bmi);

  return { initialWeight, initialHeight, weightLossEstimation, bmi };
};
