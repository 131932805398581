import { gql } from '@apollo/client';

export const SUBMIT_APPLICATION_MUTATION = gql`
  mutation SubmitApplication($input: SubmitApplicationInput!) {
    submitApplication(input: $input) {
      clientMutationId
      errors {
        message
      }
      patient {
        id
        email
        contactKey
      }
      enrollment {
        id
      }
      token
    }
  }
`;
