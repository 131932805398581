import { gql } from '@apollo/client';

export const SETUP_SOLO_SUBSCRIPTION_ORDER = gql`
  mutation SetupSoloSubscriptionOrder(
    $input: SetupSoloSubscriptionOrderInput!
  ) {
    setupSoloSubscriptionOrder(input: $input) {
      clientMutationId
      errors {
        message
      }
      enrollment {
        id
        spreeOrder {
          state
          token
          total
          number
          paymentMethodId
          paymentMethods {
            id
            name
          }
        }
        subscription {
          paymentType
          status
          spreeOrder {
            state
            token
            total
            number
            paymentMethodId
            paymentMethods {
              id
              name
            }
          }
        }
      }
    }
  }
`;
