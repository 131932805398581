import { zodResolver } from '@hookform/resolvers/zod';
import { t } from '@lingui/macro';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  isCreditCardCVVValid,
  isCreditCardHolderNameValid,
  isCreditCardMonthValid,
  isCreditCardNumberValid,
  isCreditCardYearValid,
  isCreditCardConditionValid,
} from '../../../shared/helpers/payment/validator';

export type CreditCardForm = {
  number: string;
  name: string;
  month: string;
  year: string;
  verificationValue: string;
  condition: boolean;
};

const CreditCardFormValidationSchema = (showCGU: boolean) => {
  const zob = z.object({
    number: z
      .string()
      .refine(isCreditCardNumberValid, t`payment.form.number.error`),
    name: z
      .string()
      .refine(isCreditCardHolderNameValid, t`payment.form.name.error`),
    year: z
      .string()
      .refine(isCreditCardYearValid, t`payment.form.expiry.error`),
    month: z
      .string()
      .refine(isCreditCardMonthValid, t`payment.form.expiry.error`),
    verificationValue: z
      .string()
      .refine(isCreditCardCVVValid, t`payment.form.cvv.error`),
  });

  if (showCGU) {
    return z.object({
      ...zob.shape,
      condition: z
        .boolean()
        .refine(isCreditCardConditionValid, t`payment.form.cvv.error`),
    });
  } else {
    return zob;
  }
};

type UseCreditCardFormParams = {
  onValidSubmit: (data?: CreditCardForm) => void;
  showCGU: boolean;
};

export const useCreditCardForm = ({
  onValidSubmit,
  showCGU,
}: UseCreditCardFormParams) => {
  const {
    control,
    formState: { isValid: isFormValid },
    handleSubmit,
  } = useForm<CreditCardForm>({
    defaultValues: {
      number: '',
      name: '',
      month: '',
      year: '',
      verificationValue: '',
      condition: false,
    },
    resolver: zodResolver(CreditCardFormValidationSchema(showCGU)),
    mode: 'onChange',
  });

  const onFormSubmit = handleSubmit((data) => {
    onValidSubmit(data);
  });

  return { control, isFormValid, onFormSubmit };
};
