import type {
  ChangeEventHandler,
  FunctionComponent,
  InputHTMLAttributes,
} from 'react';
import { Typography } from '../Typography/Typography';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  isDisabled?: boolean;
  isError?: boolean;
  errorLabel?: string;
  RightIcon?: FunctionComponent;
  className?: string;
  classNameInput?: string;
  onChangeText?: ChangeEventHandler<HTMLInputElement>;
  handleKeyboardSubmit?: () => void;
};

export const Input = (props: InputProps) => {
  const {
    placeholder = '',
    className = '',
    classNameInput = '',
    onChangeText,
    onFocus,
    onBlur,
    RightIcon,
    isDisabled = false,
    value,
    type = 'text',
    isError,
    errorLabel = '',
    handleKeyboardSubmit,
    ...restProps
  } = props;

  const handleOnKeyDown = (event: { key: string; keyCode: number }) => {
    if (event.key === 'Enter' || event.keyCode === 13)
      handleKeyboardSubmit && handleKeyboardSubmit();
  };

  return (
    <div className={className}>
      <div className="relative flex w-full mb-6 group bg-grey-800 rounded-xl items-center focus-within:bg-grey-700">
        <input
          value={value}
          disabled={isDisabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={handleOnKeyDown}
          className={`
            bg-transparent text-grey-0 placeholder-transparent pt-24 pb-8 px-16 rounded-xl
            block w-full
            text-sm text-gray-900 outline-none
            border-0 appearance-none peer
            ${classNameInput}`}
          type={type}
          placeholder={placeholder}
          onChange={onChangeText}
          {...restProps}
        />
        <label
          className={`
           absolute transform top-1 origin-[0] translate-x-16
            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-16 pointer-events-none
            ${
              isError
                ? 'text-orange-400'
                : 'text-grey-400 peer-placeholder-shown:text-grey-500 peer-focus:text-grey-400'
            }
            scale-75 translate-y-2 peer-focus:scale-75 peer-focus:translate-y-2
            text-sm duration-300`}
        >
          {placeholder}
        </label>
        {!!RightIcon && (
          <div className="mr-16">
            <RightIcon />
          </div>
        )}
      </div>
      {isError && errorLabel ? (
        <>
          <div className="h-4" />
          <Typography.P4 className="font-sans ml-16 text-orange-400">
            {errorLabel}
          </Typography.P4>
        </>
      ) : null}
    </div>
  );
};
