import { gql } from '@apollo/client';

export const GET_PRICE_QUERY = gql`
  query GetPrice($protocolId: ID!) {
    protocol(id: $protocolId) {
      id
      price
      initialSuccessFee
      installmentPrice
      installmentFrequency
      stepDefinitions {
        duration
      }
    }
  }
`;
