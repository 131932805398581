import createFetchFetcher from '@spree/node-fetcher';
import { makeClient } from '@spree/storefront-api-v2-sdk';
import { decamelizeKeys } from 'humps';
import { merge } from 'lodash';
import appConfig from '../../../../shared/config/appConfig';
import { createPaymentRequestPayload } from './payload';
import type { PaymentMethodWithData } from './payment.types';

// Should be IOrderResult but https://github.com/spree/spree-api-v2-js-sdk/issues/366
export type SubmitCreditCardCallback = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  order: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
) => void;

const getClient = () => {
  return makeClient({
    host: `${appConfig.apiUrl}/spree/`,
    createFetcher: createFetchFetcher,
  });
};

export const submitCreditCard = async (
  orderToken: string,
  paymentSourcePayload: PaymentMethodWithData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCompleted: (orderResult: any) => void,
) => {
  const client = getClient();
  const paymentRequestPayload =
    createPaymentRequestPayload(paymentSourcePayload);

  const payload = decamelizeKeys(paymentRequestPayload);
  const updateParams = merge(payload, { orderToken });
  const updateResult = await client.checkout.orderUpdate(updateParams);
  if (updateResult.isFail()) {
    onCompleted(updateResult);
    return updateResult;
  } else {
    const completeResult = await client.checkout.complete({ orderToken });
    onCompleted(completeResult);
    return completeResult;
  }
};

export const getOrder = async (orderToken: string, orderNumber: string) => {
  const client = getClient();
  const orderResponse = await client.order.status({
    order_token: orderToken,
    order_number: orderNumber,
  });
  return orderResponse;
};
