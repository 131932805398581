import type { BaseMutationOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useCallback } from 'react';
import type {
  Payment,
  SetupSoloSubscriptionOrderMutation,
  SetupSoloSubscriptionOrderMutationVariables,
} from '../../../shared/infra/queries.types';
import { useAddToast } from '../../toaster/domain/toaster.store';
import { useEnrollmentStore } from '../domain/enrollment.store';
import { SETUP_SOLO_SUBSCRIPTION_ORDER } from './submitSetupSoloSubscriptionOrder.mutation';

type UseSubmitSetupSoloSubscriptionOrderParams = {
  onCompleted: BaseMutationOptions<SetupSoloSubscriptionOrderMutation>['onCompleted'];
};

export const useSubmitSetupSoloSubscriptionOrder = (
  { onCompleted }: UseSubmitSetupSoloSubscriptionOrderParams,
  paymentType: Payment,
) => {
  const { addToast } = useAddToast();
  const { setOrder, enrollmentId } = useEnrollmentStore((state) => state);
  const onError = () =>
    addToast({
      id: 'setupSoloSubscriptionOrderError',
      message: t`error.default`,
    });

  const onCompletedWithStore = (data: SetupSoloSubscriptionOrderMutation) => {
    if (data.setupSoloSubscriptionOrder) {
      const order = data.setupSoloSubscriptionOrder.enrollment?.spreeOrder;
      if (order) {
        setOrder(order);
      }
    }
    onCompleted?.(data);
  };
  const [setupSoloSubscriptionOrderMutation, { loading, error }] = useMutation<
    SetupSoloSubscriptionOrderMutation,
    SetupSoloSubscriptionOrderMutationVariables
  >(SETUP_SOLO_SUBSCRIPTION_ORDER, {
    onCompleted: onCompletedWithStore,
    onError,
  });

  const setupSoloSubscriptionOrder = useCallback(async () => {
    if (!enrollmentId) {
      addToast({
        id: 'setupSoloSubscriptionOrderError',
        message: t`error.default`,
      });
      return;
    }
    setupSoloSubscriptionOrderMutation({
      variables: {
        input: {
          enrollmentId,
          paymentType,
        },
      },
    });
  }, [enrollmentId, paymentType, setupSoloSubscriptionOrderMutation, addToast]);

  return {
    setupSoloSubscriptionOrder,
    loading,
    error,
  };
};
