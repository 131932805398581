import { last } from 'lodash';
import { useEffect, useState } from 'react';

type Stage = {
  label: string;
  second: number;
  value: number;
};

type StageLoadingTimerProps = {
  initialLabel: string;
  totalDuration: number;
  tick: number;
  stages: Stage[];
  onComplete?: () => void;
};

export const useStageLoadingTimer = ({
  initialLabel,
  totalDuration,
  tick,
  stages,
  onComplete,
}: StageLoadingTimerProps) => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingLabel, setLoadingLabel] = useState(initialLabel);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingProgress((v) => Math.min(totalDuration, v + tick));
    }, tick * 1000);
    const timers = stages.map((stage) =>
      setTimeout(() => {
        setLoadingLabel(stage.label);
        setLoadingProgress(stage.value);
      }, stage.second * 1000),
    );
    const lastStageTiming = last(stages)?.second;
    const completeTime = lastStageTiming ? lastStageTiming + 1 : totalDuration;
    const onCompleteTimer = setTimeout(
      () => onComplete && onComplete(),
      completeTime * 1000,
    );

    return () => {
      clearInterval(intervalId);
      clearTimeout(onCompleteTimer);
      timers.map((timer) => clearTimeout(timer));
    };
  }, [onComplete, stages, tick, totalDuration]);

  return {
    isLoading: loadingProgress >= totalDuration,
    loadingLabel,
    loadingProgress,
    maxProgress: totalDuration,
  };
};
