import { t } from '@lingui/macro';
import { useState, type FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { Input } from '../../shared/view/components/Input/Input';
import { useWorkflowNavigation } from '../../shared/view/components/Workflow';
import { useAddToast } from '../toaster/domain/toaster.store';

import { ErrorPage } from '../../shared/view/components/ErrorPage/ErrorPage';
import { Typography } from '../../shared/view/components/Typography/Typography';
import { OnboardingTemplateScreen } from '../Onboarding/shared/view/OnboardingTemplateScreen';
import type { SubmitCreditCardCallback } from './domain/payment/payment.rest';
import type { CreditCardForm } from './domain/useCreditCardForm';
import { useCreditCardForm } from './domain/useCreditCardForm';
import { useSubmitCreditCard } from './domain/useSubmitCreditCard';
import { Button } from '../../shared/view/components/Button/Button';

import check from '../../stories/assets/check.svg';
import { useGetEnrollment } from './infra/useGetEnrollment';
import { LoadingView } from '../../shared/view/components/LoadingView/LoadingView';

export const CardRegister: FunctionComponent = () => {
  const [value, setValue] = useState(false);
  const { addToast } = useAddToast();
  const { navigation } = useWorkflowNavigation();
  const {
    enrollment,
    enrollmentNeedsCreditCardEnrollment,
    loading,
    error,
    refetch,
  } = useGetEnrollment();

  if (!enrollmentNeedsCreditCardEnrollment) navigation.goNext();

  const onCompleted: SubmitCreditCardCallback = (receivedOrder, error) => {
    if (receivedOrder && !error) {
      navigation.goNext();
    } else {
      addToast({
        id: 'submissionErrors',
        message: error,
      });
    }
  };

  const order = enrollment?.spreeOrder;

  const { loading: cardLoading, submitCreditCard } = useSubmitCreditCard({
    onCompleted,
    order,
  });

  const onValidSubmit = async (formData?: CreditCardForm) => {
    if (!formData) return;
    submitCreditCard(formData);
  };

  const { control, isFormValid, onFormSubmit } = useCreditCardForm({
    onValidSubmit,
    showCGU: true,
  });

  if (error) {
    return <ErrorPage error={error} onRetry={refetch} isRetrying={loading} />;
  }

  if (loading || enrollmentNeedsCreditCardEnrollment === undefined) {
    return <LoadingView isLoading={loading} />;
  }

  return (
    <OnboardingTemplateScreen
      title={t`card-register.title`}
      progressBar={{
        label: t`enrollment.progress-bar`,
        currentStep: 4,
        maxStep: 4,
      }}
    >
      <p className="leading-6">
        <Typography.P2 className="text-grey-300">
          {t`card-register.subtitle`}
        </Typography.P2>
      </p>
      <div className="h-24" />
      <Controller
        control={control}
        name="number"
        render={({
          field: { onChange, value, onBlur },
          fieldState: { error, isTouched, isDirty },
        }) => (
          <Input
            type="tel"
            value={value}
            onChangeText={onChange}
            onBlur={isDirty ? onBlur : undefined}
            placeholder={t`payment.form.number.placeholder`}
            isError={!!error && isTouched && isDirty}
            errorLabel={error?.message}
            autoComplete="cc-number"
            classNameInput="border-[1.5px] border-grey-500 p-2"
          />
        )}
      />
      <div className="h-16" />
      <Controller
        control={control}
        name="name"
        render={({
          field: { onChange, value, onBlur },
          fieldState: { error, isTouched, isDirty },
        }) => (
          <Input
            type="string"
            value={value}
            onChangeText={onChange}
            onBlur={isDirty ? onBlur : undefined}
            placeholder={t`payment.form.name.placeholder`}
            isError={!!error && isTouched && isDirty}
            errorLabel={error?.message}
            autoComplete="cc-name"
            classNameInput="border-[1.5px] border-grey-500 p-2"
          />
        )}
      />
      <div className="h-16" />
      <div className="flex flex-row">
        <div className="flex gap-8">
          <div className="flex-1 flex gap-8">
            <div className="flex flex-col">
              <div className="flex flex-row">
                <Controller
                  control={control}
                  name="month"
                  render={({
                    field: { onChange, value, onBlur },
                    fieldState: { error, isTouched, isDirty },
                  }) => (
                    <Input
                      type="tel"
                      className="flex-1 mr-[8px]"
                      value={value}
                      onChangeText={onChange}
                      onBlur={isDirty ? onBlur : undefined}
                      placeholder={t`payment.form.month.placeholder`}
                      isError={!!error && isTouched && isDirty}
                      errorLabel={error?.message}
                      autoComplete="cc-exp-month"
                      classNameInput="border-[1.5px] border-grey-500 p-2"
                      maxLength={2}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="year"
                  render={({
                    field: { onChange, value, onBlur },
                    fieldState: { error, isTouched, isDirty },
                  }) => (
                    <Input
                      type="tel"
                      className="flex-1"
                      value={value}
                      onChangeText={onChange}
                      onBlur={isDirty ? onBlur : undefined}
                      placeholder={t`payment.form.year.placeholder`}
                      isError={!!error && isTouched && isDirty}
                      errorLabel={error?.message}
                      autoComplete="cc-exp-year"
                      classNameInput="border-[1.5px] border-grey-500 p-2"
                      maxLength={2}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="flex-1 flex gap-8">
            <div className="flex-1" />
            <div className="flex flex-col">
              <Controller
                control={control}
                name="verificationValue"
                render={({
                  field: { onChange, value, onBlur },
                  fieldState: { error, isTouched, isDirty },
                }) => (
                  <Input
                    type="tel"
                    className="flex-2"
                    value={value}
                    onChangeText={onChange}
                    onBlur={isDirty ? onBlur : undefined}
                    placeholder={t`payment.form.cvv.placeholder`}
                    isError={!!error && isTouched && isDirty}
                    errorLabel={error?.message}
                    autoComplete="cc-csc"
                    classNameInput="border-[1.5px] border-grey-500 p-2"
                    maxLength={3}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-40" />
      <div className="flex text-xs">
        <label className="relative select-none mr-[35px] text-title-s checkboxContainer">
          <Controller
            control={control}
            name="condition"
            render={({ field: { onChange, value } }) => (
              <input
                type="checkbox"
                className="absolute opacity-0 h-0 w-0 cursor-pointer"
                onChange={(e) => {
                  setValue(e.target.checked);
                  onChange(e);
                }}
                checked={value}
              />
            )}
          />
          <span className="absolute top-0 left-0 h-[20px] w-[20px] border-[1.5px] border-grey-500 p-2 bg-[#313232] rounded-md checkboxInput">
            <img
              className={`mt-[2px] ${value ? '' : 'hidden'}`}
              src={check}
              alt="condition validée"
            />
          </span>
        </label>
        <label
          htmlFor="link-checkbox"
          className="text-p4 ml-2 text-sm font-medium text-grey-300"
        >
          {t`payment.condition`}
          <a
            href="https://www.charles.co/doc/CGUV_CHARLES_CERCLE.pdf"
            className="text-xs text-grey-0 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            {t`payment.cgu`}
          </a>
          {t`payment.condition.text`}
          <a
            href="https://www.charles.co/politique-de-confidentialite/"
            className="text-xs text-grey-0 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            {t`payment.politique`}
          </a>
        </label>
      </div>
      <div className="h-24" />
      <Button.Primary
        className="w-full font-semibold"
        isDisabled={!isFormValid || cardLoading}
        isLoading={cardLoading}
        onClick={onFormSubmit}
        label={t`card-register.approve.label`}
      />
      <div className="h-24" />

      <Typography.P3 className="text-grey-400 leading-6">{t`payment.subtitle`}</Typography.P3>
    </OnboardingTemplateScreen>
  );
};
