import { Tooltip } from '../../../shared/view/components/Tooltip/Tooltip';
import { ErrorIcon } from '../../../shared/view/icons/ErrorIcon';
import type { Toast } from '../domain/toaster.store';
import { useToasterStore } from '../domain/toaster.store';

export const Toasters = () => {
  const toasts = useToasterStore((state) => state.toasts);

  return (
    <div className="fixed pointer-events-none w-full max-w-[535px] flex flex-col items-center top-8 p-24">
      {toasts.map((toast) => {
        return (
          <div className="w-full" key={toast.id}>
            <Toaster {...toast} />
            <div className="h-16" />
          </div>
        );
      })}
    </div>
  );
};

const ToasterErrorIcon = () => <ErrorIcon color="black" />;

const Toaster = ({ message }: Toast) => (
  <Tooltip
    Icon={ToasterErrorIcon}
    className="animation-fadeout w-full font-semibold bg-orange-100"
    textClassName="text-grey-800 font-normal"
    label={message}
  />
);
