import { useMemo } from 'react';
import { SurveyType } from '../../../../shared/infra/queries.types';
import { createSurveyModel, useGetSurveys } from './useGetSurveys';

export const useMotivationSurveyPageCount = () => {
  const { surveyData: motivationSurveyData } = useGetSurveys(
    SurveyType.Motivation,
  );
  const motivationSurvey = useMemo(
    () => createSurveyModel(motivationSurveyData?.payload),
    [motivationSurveyData?.payload],
  );
  const motivationSurveyPageCount = motivationSurvey?.pageCount ?? 0;
  return motivationSurveyPageCount;
};
