import type { BaseMutationOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useCallback } from 'react';
import { analytics } from '../../../../app/Analytics/analytics.singleton';
import type {
  SubmitApplicationMutation,
  SubmitApplicationMutationVariables,
} from '../../../../shared/infra/queries.types';
import { useEnrollmentStore } from '../../../Enrollment/domain/enrollment.store';
import { useAddToast } from '../../../toaster/domain/toaster.store';
import type { SignInForm } from '../../Finalization/domain/useSignInForm';
import { useOnboardingStore } from '../domain/onboarding.store';
import { SUBMIT_APPLICATION_MUTATION } from './submitApplication.mutation';

export type UseSubmitApplicationParams = {
  onCompleted: BaseMutationOptions<SubmitApplicationMutation>['onCompleted'];
};

export const useSubmitApplication = ({
  onCompleted,
}: UseSubmitApplicationParams) => {
  const {
    protocolId,
    protocolName,
    medicalSurveyAnswers,
    motivationSurveyAnswers,
  } = useOnboardingStore(useCallback((state) => state, []));

  const { addToast } = useAddToast();
  const setEnrollmentId = useEnrollmentStore((state) => state.setEnrollmentId);

  const onError = () =>
    addToast({
      id: 'submissionErrors',
      message: t`error.default`,
    });

  const onCompletedWithSetTokenAndAnalytics = useCallback(
    (data: SubmitApplicationMutation) => {
      if (data.submitApplication) {
        if (data.submitApplication.token) {
          localStorage.setItem('token', data.submitApplication.token);
        }

        if (data.submitApplication.patient) {
          analytics.track('lead_created', {
            email: data.submitApplication.patient.email,
            protocol: protocolName,
          });
          analytics.identify(data.submitApplication.patient.contactKey, {
            email: data.submitApplication.patient.email,
            protocol: protocolName,
          });
        }

        if (data.submitApplication.enrollment) {
          const enrollment = data.submitApplication.enrollment;
          setEnrollmentId(enrollment.id);
        }
      }
      onCompleted?.(data);
    },
    [protocolName, onCompleted, setEnrollmentId],
  );

  const [submitApplicationMutation, { loading }] = useMutation<
    SubmitApplicationMutation,
    SubmitApplicationMutationVariables
  >(SUBMIT_APPLICATION_MUTATION, {
    onCompleted: onCompletedWithSetTokenAndAnalytics,
    onError,
  });

  const submitApplication = useCallback(
    async (formData: SignInForm) => {
      if (!protocolId || !medicalSurveyAnswers) {
        addToast({
          id: 'informationMissingError',
          message: t`sign-in.submit.missing-information-error`,
        });
        return;
      }

      const attributes = {
        email: formData.email,
        password: formData?.password,
        protocolId,
        medicalSurveyAnswers,
        motivationSurveyAnswers,
        hasAcceptedToBeContacted: formData.hasAcceptedToBeContacted,
      };

      submitApplicationMutation({
        variables: {
          input: {
            attributes: attributes,
          },
        },
      });
    },
    [
      protocolId,
      medicalSurveyAnswers,
      motivationSurveyAnswers,
      submitApplicationMutation,
      addToast,
    ],
  );

  return {
    submitApplication,
    loading,
  };
};
