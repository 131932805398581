// https://surveyjs.io/form-library/examples/survey-customcss/reactjs#content-docs

const ctaButtonStyle =
  'w-full whitespace-normal font-semibold bg-grey-0 disabled:bg-grey-700 active:bg-grey-100 rounded-xl text-grey-900 disabled:text-grey-400 p-12 cursor-pointer';

const tooltipStyle =
  'sd-question__description sd-question__description--under-input text-p3 text-grey-100 bg-grey-800 whitespace-normal flex items-center p-12 rounded-lg mt-16';

export const surveyStyle = {
  header: 'hidden',
  question: {
    number: 'hidden',
    title:
      'font-sans-ext whitespace-normal text-grey-0 text-title-l font-semibold pb-24 leading-tight',
    requiredText: 'hidden',
    actionBar: {
      root: 'hidden',
    },
    content: 'question__content h-full flex flex-col',
    mainRoot: 'sd-element sd-question sd-row__question h-full flex flex-col',
    descriptionUnderInput: tooltipStyle,
  },
  text: {
    root: 'sd-text text-grey-0 outline-none bg-grey-700 p-16 rounded-2xl',
  },
  comment: {
    root: 'textareaSurveyJs bg-grey-800 text-grey-0 outline-none w-full h-[220px] text-p2 border border-grey-700 rounded-3xl p-16',
  },
  page: {
    root: 'p-0 h-full pb-64',
  },
  navigation: {
    next: ctaButtonStyle,
    complete: ctaButtonStyle,
  },
  actionBar: {
    root: 'w-full fixed max-w-[535px] bottom-16 px-16',
  },
  bodyNavigationButton: '',
  checkbox: {
    controlLabel: 'font-semibold',
    materialDecorator:
      'bg-grey-700 w-24 h-24 border border-grey-600 rounded-md flex items-center justify-center group-[.sd-checkbox--checked]:bg-grey-0',
    item: 'p-0 group',
    label:
      'whitespace-normal text-grey-0 p-16 mt-8 cursor-pointer bg-grey-800 lg:hover:bg-grey-700 flex flex-row-reverse rounded-2xl justify-between items-center group-[.sd-checkbox--checked]:bg-grey-700',
  },
  radiogroup: {
    controlLabel:
      'checkboxLabel block whitespace-normal text-grey-0 bg-grey-800 peer-checked:bg-grey-700 p-12 m-4 cursor-pointer lg:hover:bg-grey-700 w-full font-medium rounded-lg flex justify-between items-center',
    rootMultiColumn: 'sd-selectbase--multi-column flex-col',
    materialDecorator: 'hidden',
    label: 'flex',
    itemControl: 'hidden peer',
    item: 'p-0',
    // remove the default sd-selectbase__column class from column to avoid the padding right on :not:last-child
    column: '',
  },
  root: 'surveyRoot w-full flex-grow flex',
  body: 'h-full flex flex-col justify-between pb-16 items-center',
  pageRow: 'h-full',
  container: 'w-full h-full',
};
