import type { FunctionComponent } from 'react';

export type ToggleProps = {
  className?: string;
  isToggled: boolean;
  isDisabled?: boolean;
  onChange?: () => void;
};

export const Toggle: FunctionComponent<ToggleProps> = ({
  className = '',
  isToggled,
  isDisabled,
  onChange,
}: ToggleProps) => {
  return (
    <label
      className={`relative cursor-pointer w-[60px] h-32 rounded-full flex-shrink-0 ${className}`}
    >
      <input
        type="checkbox"
        checked={isToggled}
        disabled={isDisabled}
        onChange={onChange}
        className="sr-only peer"
      />
      <div
        className="peer-checked:bg-teal-300 w-[60px] h-32 bg-grey-700 rounded-full absolute transition-all
        peer-disabled:bg-grey-200 peer-disabled:peer-checked:bg-grey-500"
      />
      <div className="shadow-e100 peer-checked:translate-x-[30px] peer-checked:left-auto absolute top-2 bottom-2 left-2 bg-grey-0 rounded-full w-[28px] transition-all" />
    </label>
  );
};
