import type { FunctionComponent, PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  onClose: () => void;
  show: boolean;
}>;

export const Modal: FunctionComponent<Props> = ({
  children,
  onClose: _onClose,
  show,
}) => {
  if (!show) {
    return null;
  }
  return (
    <div
      className="relative z-10 bg-grey-900"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-grey-900 bg-opacity-100" />

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex justify-center h-full">
          <div className="w-[535px] h-full overflow-x-hidden">{children}</div>
        </div>
      </div>
    </div>
  );
};
