import { t } from '@lingui/macro';
import { Typography } from '../../shared/view/components/Typography/Typography';
import { BackgroundCircles } from '../Onboarding/shared/view/BackgroundCircles';
import { ScreenContentContainer } from '../Onboarding/shared/view/ScreenContentContainer';
import { AppGoogleSvg } from './view/DownloadIcon/AppGoogleSvg';
import { AppAppleSvg } from './view/DownloadIcon/AppAppleSvg';
export const WelcomeScreen = () => {
  return (
    <ScreenContentContainer>
      <div />
      <div className="flex flex-col items-center">
        <div className="h-16" />
        <Typography.Title className="text-center">
          {t`welcome.title`}
        </Typography.Title>
        <div className="h-24" />
        <Typography.P2 className="text-center text-grey-300">
          {t`welcome.summary`}
        </Typography.P2>
        <div className="h-32" />
        <Typography.P2 className="text-center">
          {t`welcome.long-text`}
        </Typography.P2>
        <div className="h-40" />
        <Typography.P2 className="text-center">
          {t`welcome.download-app`}
        </Typography.P2>
        <div className="h-24" />
        <div className="flex flex-row">
          <div className="mr-24">
            <a
              href="https://apps.apple.com/fr/app/cercle-by-charles/id6446853329"
              target="_blank"
              rel="external noreferrer"
            >
              <AppAppleSvg />
            </a>
          </div>
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=co.charles.cercle&hl=fr"
              target="_blank"
              rel="external noreferrer"
            >
              <AppGoogleSvg />
            </a>
          </div>
        </div>
      </div>
      <div />
      <BackgroundCircles />
    </ScreenContentContainer>
  );
};
