import { t } from '@lingui/macro';
import {
  supportPhoneNumber,
  supportPhoneNumberLink,
} from '../../../shared/config/constants';
import { Button } from '../../../shared/view/components/Button/Button';
import { Typography } from '../../../shared/view/components/Typography/Typography';
import { ChatIcon } from '../../../shared/view/icons/Chat.icon';
import { BackgroundCircles } from '../../Onboarding/shared/view/BackgroundCircles';
import { ScreenContentContainer } from '../../Onboarding/shared/view/ScreenContentContainer';

export const DoneFailed = ({ onClick }: { onClick: () => void }) => (
  <ScreenContentContainer>
    <div />
    <div className="flex flex-col items-center">
      <Typography.Title>{t`payment.challenge.title`}</Typography.Title>
      <div className="h-16" />
      <ChatIcon />
      <div className="h-16" />
      <Typography.Title className="text-title-s text-center">
        {t`payment.challenge.done-failed.title`}
        <a href={supportPhoneNumberLink}>{supportPhoneNumber}</a>
      </Typography.Title>
      <div className="h-24" />
      <Button.Primary
        label={t`payment.challenge.done-failed.cta`}
        onClick={() => onClick()}
      />
    </div>
    <div />
    <BackgroundCircles />
  </ScreenContentContainer>
);
