import { fr } from 'date-fns/locale';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ApolloProvider } from './app/ApolloClient/ApolloClient.provider';
import { I18nProvider } from './app/i18n/I18n.provider';
import './app/MarkdownFormatter/Mark.singleton';
import { CheckInWorkflow } from './app/workflows/CheckIn';
import Enrollment from './app/workflows/Enrollment';
import { SecureChallengePopup } from './modules/SecureChallenge/SecureChallengePopup';
import { SecureChallengeStatus } from './modules/SecureChallenge/SecureChallengeStatus';
import { Toasters } from './modules/toaster/view/Toasters';
import { IntercomProvider } from 'react-use-intercom';
import type { IntercomProps } from 'react-use-intercom';
import { WorkflowV2OrFreeTrial } from './app/workflows/WorkflowV2OrFreeTrial';
import Subscription from './app/workflows/Subscription';

setDefaultOptions({
  locale: fr,
  weekStartsOn: 1,
});

interface CustomIntercomProps extends IntercomProps {
  targetPlatform: string;
  targetStack: string;
  patientWorkflow: string;
}

const routes = [
  {
    path: '/*',
    element: <WorkflowV2OrFreeTrial />,
  },
  {
    path: 'checkin/*',
    element: <CheckInWorkflow />,
  },
  {
    path: 'enrollment/*',
    element: <Enrollment />,
  },
  {
    path: 'challenge/:status',
    element: <SecureChallengeStatus />,
  },
  {
    path: 'subscription/*',
    element: <Subscription />,
  },
];

const router = createBrowserRouter(routes);

function AppWithProviders() {
  const intercomId = process.env.REACT_APP_INTERCOM_ID || '';

  const intercom: CustomIntercomProps = {
    targetPlatform: 'cercle',
    targetStack: 'onboarding',
    patientWorkflow: 'onboarding',
  };

  return (
    <div className="flex justify-center h-full">
      <div className="w-[535px] h-full overflow-x-hidden">
        <IntercomProvider
          appId={intercomId}
          initializeDelay={3000}
          autoBoot
          autoBootProps={intercom}
        >
          <I18nProvider>
            <ApolloProvider>
              <RouterProvider router={router} />
            </ApolloProvider>
          </I18nProvider>
          <Toasters />
          <SecureChallengePopup />
        </IntercomProvider>
      </div>
    </div>
  );
}

export default AppWithProviders;
