import { useLazyQuery } from '@apollo/client';
import type {
  GetProtocolSpecificDataQuery,
  GetProtocolSpecificDataQueryVariables,
} from '../../../../shared/infra/queries.types';
import { useOnboardingStore } from '../domain/onboarding.store';
import 'survey-core/survey.i18n';
import { GET_PROTOCOL_SPECIFIC_DATA } from './getProtocolSpecificData.query';
import { useEffect } from 'react';

export const usePrefetchProtocolSpecificData = () => {
  const protocolId = useOnboardingStore((state) => state.protocolId);

  const [getProtocolSpecificData] = useLazyQuery<
    GetProtocolSpecificDataQuery,
    GetProtocolSpecificDataQueryVariables
  >(GET_PROTOCOL_SPECIFIC_DATA);

  useEffect(() => {
    if (protocolId) {
      getProtocolSpecificData({ variables: { protocolId } });
    }
  }, [protocolId, getProtocolSpecificData]);
};
