import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { CheckInScreen } from '../../../modules/CheckIn/CheckIn.screen';
import { useCheckInSurveyStore } from '../../../modules/CheckIn/domain/checkinSurvey.store';

import {
  isInWebView,
  postGoBackOnFirstPage,
  postMessageToReactNative,
} from '../../../shared/helpers/webview/utils';
import Workflow, {
  useWorkflowNavigation,
} from '../../../shared/view/components/Workflow';
import { useSendAnalyticsPageViewOnUrlChange } from '../../Analytics/useSendAnalyticsPageViewOnUrlChange';

const FakeScreenThatAlwaysGoNext = () => {
  const { navigation } = useWorkflowNavigation();

  useEffect(() => {
    if (window.history.length !== 1) {
      postGoBackOnFirstPage();
    }
    window.location.pathname = 'checkin/checkin-survey';
  }, [navigation]);
  return <div />;
};

const workflowSteps = [
  {
    path: 'next',
    comp: <FakeScreenThatAlwaysGoNext />,
  },
  {
    path: 'checkin-survey',
    comp: <CheckInScreen />,
  },
];

export function CheckInWorkflow() {
  useSendAnalyticsPageViewOnUrlChange();
  const { setCheckInSurveyFromString, checkInSurveyAnswers } =
    useCheckInSurveyStore(
      useCallback(
        (store) => ({
          setCheckInSurveyFromString: store.setCheckInSurveyFromString,
          checkInSurveyAnswers: store.checkInSurveyAnswers,
        }),
        [],
      ),
    );
  const navigate = useNavigate();

  useEffect(() => {
    const w = window as unknown as { surveyData: string };
    if (w.surveyData) {
      setCheckInSurveyFromString(w.surveyData);
    }
  }, [setCheckInSurveyFromString, checkInSurveyAnswers]);

  const handleGoBack = useCallback(() => {
    isInWebView() ? postGoBackOnFirstPage() : navigate(-1);
  }, [navigate]);

  const onComplete = useCallback(() => {
    postMessageToReactNative({
      type: 'surveyResponse',
      data: checkInSurveyAnswers,
    });
  }, [checkInSurveyAnswers]);

  return (
    <Workflow
      steps={workflowSteps}
      goBack={handleGoBack}
      complete={onComplete}
    />
  );
}
