import { gql } from '@apollo/client';

export const GET_PROTOCOL_SPECIFIC_DATA = gql`
  query GetProtocolSpecificData($protocolId: ID!) {
    protocol(id: $protocolId) {
      id
      price
      initialSuccessFee
      installmentPrice
      installmentFrequency
      surveys {
        title
        payload
        type
      }
      presentation {
        id
        medicalSearchingTooltip
        medicalThankYouTooltip
        methodExpertDescription
        methodExpertTags
        methodExpertVideo {
          name
          url
        }
        methodWhatWeDo
        methodWhatWeDoNot
        methodPlanTooltip
        methodPlanProgressPhaseTitle
        methodPlanProgressPhaseDescription
        methodPlanConsolidationPhaseTitle
        methodPlanConsolidationPhaseDescription
        methodTestimony {
          audio {
            url
          }
          avatar {
            alt
            url
          }
          highlight
        }
        waitingRoomVideo {
          url
        }
        waitingRoomTestimonies {
          avatar {
            url
            alt
          }
          audio {
            url
          }
        }
      }
    }
  }
`;
