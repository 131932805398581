import type { SurveyModel } from 'survey-core';
import { create } from 'zustand';
import { createSurveyModel } from '../../Onboarding/shared/infra/useGetSurveys';

export type CheckInSurveyState = {
  checkInSurvey: SurveyModel | null;
  checkInSurveyAnswers: Record<string, string>;
  setCheckInSurveyFromString: (checkInSurveyString: string) => void;
  setCheckInSurveyAnswers: (answer: Record<string, string>) => void;
};

export const useCheckInSurveyStore = create<CheckInSurveyState>()((set) => ({
  checkInSurvey: null,
  checkInSurveyAnswers: {},
  setCheckInSurveyFromString: (checkInSurveyString) =>
    set({ checkInSurvey: createSurveyModel(checkInSurveyString) }),
  setCheckInSurveyAnswers: (answers) => set({ checkInSurveyAnswers: answers }),
}));
