import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { analytics } from './analytics.singleton';

export const useSendAnalyticsPageViewOnUrlChange = () => {
  const location = useLocation();

  useEffect(() => {
    analytics.page(location.pathname);
  }, [location.pathname]);
};
