import { zodResolver } from '@hookform/resolvers/zod';
import { t } from '@lingui/macro';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

export type SignInForm = {
  email: string;
  password: string;
  hasAcceptedToBeContacted: boolean;
};

const signInFormValidationSchema = z.object({
  email: z.string().email(t`sign-in.form.email.error`),
  password: z.string().min(8, t`sign-in.form.password.error`),
  hasAcceptedToBeContacted: z.literal(true),
});

type UseSignInFormParams = {
  onValidSubmit: (data?: SignInForm) => void;
};

export const useSignInForm = ({ onValidSubmit }: UseSignInFormParams) => {
  const randomPassword = uuidv4();
  const {
    control,
    formState: { isValid: isFormValid },
    handleSubmit,
  } = useForm<SignInForm>({
    defaultValues: {
      email: '',
      password: randomPassword,
      hasAcceptedToBeContacted: true,
    },
    resolver: zodResolver(signInFormValidationSchema),
    mode: 'onChange',
  });

  const onFormSubmit = handleSubmit((data) => {
    onValidSubmit(data);
  });

  return { control, isFormValid, onFormSubmit };
};
