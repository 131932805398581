import { PaymentScreen } from '../../../../modules/Enrollment/Payment.screen';
import { WelcomeScreen } from '../../../../modules/Enrollment/Welcome.screen';
import { NestedWorkflow } from '../../../../shared/view/components/Workflow';

export const EnrollmentInOnboarding = () => (
  <NestedWorkflow
    steps={[
      {
        path: 'souscription',
        comp: <PaymentScreen />,
      },
      {
        path: 'confirmation',
        comp: <WelcomeScreen />,
      },
    ]}
  />
);
