import Lottie from 'lottie-react';
import greenCircleBackground from '../../../../shared/view/animations/GreenCircle.json';
import yellowCircleBackground from '../../../../shared/view/animations/YellowCircle.json';
import blueCircleBackground from '../../../../shared/view/animations/BlueCircle.json';

export const BackgroundCircles = () => {
  return (
    <div className="fixed h-full -z-10">
      <Lottie
        className="absolute left-[20px] bottom-[-50px]"
        style={{ width: '230px', height: '230px' }}
        animationData={yellowCircleBackground}
      />
      <Lottie
        className="absolute right-[100px] bottom-[100px]"
        style={{ width: '150px', height: '150px' }}
        animationData={blueCircleBackground}
      />
      <Lottie
        className="absolute left-[100px] top-[0px]"
        style={{ width: '110px', height: '110px' }}
        animationData={greenCircleBackground}
      />
    </div>
  );
};
