import type { FunctionComponent } from 'react';
import { Graphic } from '../../../../modules/Onboarding/Method/Graphic.screen';
import { PaymentTypeSelection } from '../../../../modules/Onboarding/Method/PaymentTypeSelection.screen';
import { ProgramSummary } from '../../../../modules/Onboarding/Method/ProgramSummary.screen';
import { WeightLoaderScreen } from '../../../../modules/Onboarding/Method/WeightLoader.screen';
import { NestedWorkflow } from '../../../../shared/view/components/Workflow';

export const MethodV2: FunctionComponent = () => {
  const steps = [
    {
      path: 'analyse',
      comp: <WeightLoaderScreen />,
    },
    {
      path: 'graphic',
      comp: <Graphic />,
    },
    {
      path: 'program-summary',
      comp: <ProgramSummary />,
    },
    {
      path: 'selection',
      comp: <PaymentTypeSelection />,
    },
  ];

  return <NestedWorkflow steps={steps} />;
};
