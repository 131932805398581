import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type EnrollmentStoreType = {
  enrollmentId: string | undefined;
  cercle: Cercle | undefined;
  order: Order | undefined;
  setEnrollmentId: (enrollmentId: string) => void;
  setOrder: (order: Order) => void;
  setSelectedCercleAndOrder: (cercle: Cercle, order: Order) => void;
};

export type Cercle = {
  id: string;
  price: number;
  installmentPrice: number;
  seatsLeft: number;
  plannedStartAt: string;
};

export type Order = {
  token: string;
  total: number;
  number: string;
  paymentMethodId: string;
  paymentMethods?: PaymentMethod[];
  state: string;
};

export type PaymentMethod = {
  id: string;
  name: string;
};

export const useEnrollmentStore = create<EnrollmentStoreType>()(
  persist(
    (set) => {
      return {
        enrollmentId: undefined,
        cercle: undefined,
        order: undefined,
        setEnrollmentId: (enrollmentId) =>
          set(() => ({
            enrollmentId,
          })),
        setOrder: (order: Order) =>
          set(() => ({
            order,
          })),
        setSelectedCercleAndOrder: (cercle: Cercle, order: Order) =>
          set(() => ({
            cercle,
            order,
          })),
      };
    },
    { name: 'enrollment' },
  ),
);
