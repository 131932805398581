import { t } from '@lingui/macro';
import type { GetEnrollmentQuery } from '../../../shared/infra/queries.types';
import { Payment } from '../../../shared/infra/queries.types';
import { Typography } from '../../../shared/view/components/Typography/Typography';

type Props = {
  enrollment: GetEnrollmentQuery['enrollment'];
};

export const SubscriptionPaymentExplanation = ({ enrollment }: Props) => {
  const subscription = enrollment?.subscription;
  const order = subscription?.spreeOrder;

  if (order == null || subscription == null) {
    return null;
  }

  if (subscription.paymentType === Payment.Installment) {
    return (
      <p className="leading-6">
        <Typography.P2 className="text-grey-300">
          {t`payment.installment.explanation`}
        </Typography.P2>
      </p>
    );
  }
  if (subscription.paymentType === Payment.OneOff) {
    return (
      <p className="leading-6">
        <Typography.P2 className="text-grey-300">
          {t`payment.subtitle1`}
          <Typography.P2 className="font-semibold text-grey-300">
            {order.total}
            {'€'}
          </Typography.P2>
        </Typography.P2>
      </p>
    );
  }
  return null;
};
