import { useEffect } from 'react';
import { useParams } from 'react-router';
import { notifyFailure, notifySuccess } from '../domain/secureMessages';

export const SecureChallengeStatus = () => {
  const { status } = useParams();
  useEffect(() => {
    const success = status === 'success';
    success ? notifySuccess() : notifyFailure();
  }, [status]);

  return <div />;
};
