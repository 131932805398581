import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import type {
  GetEnrollmentQuery,
  GetEnrollmentQueryVariables,
} from '../../../shared/infra/queries.types';
import { useEnrollmentStore } from '../domain/enrollment.store';
import { GET_ENROLLMENT } from './getEnrollment.query';

export const useGetEnrollment = (forceFetch = true) => {
  const { enrollmentId, setOrder } = useEnrollmentStore(
    useCallback((state) => state, []),
  );
  const { data, loading, error, refetch } = useQuery<
    GetEnrollmentQuery,
    GetEnrollmentQueryVariables
  >(GET_ENROLLMENT, {
    variables: {
      enrollmentId: enrollmentId ?? '',
    },
    fetchPolicy: forceFetch ? 'no-cache' : 'cache-first',
    skip: !enrollmentId,
    onCompleted: (data) => {
      if (data?.enrollment?.spreeOrder) {
        setOrder(data.enrollment.spreeOrder);
      }
    },
  });

  const enrollmentNeedsCreditCardEnrollment =
    data?.enrollment?.spreeOrder?.state !== 'complete' ?? false;

  return {
    enrollment: data?.enrollment,
    enrollmentNeedsCreditCardEnrollment,
    loading,
    error,
    refetch,
  };
};
