import { memo } from 'react';
import type { IconType } from '../../../../shared/view/icons/Icon.types';

const DEFAULT_ICON_SIZE_PX = 40;

const CheckCircleIconSvg: IconType = ({
  size = DEFAULT_ICON_SIZE_PX,
  className = 'stroke-grey-0',
  strokeWidth = '1.5',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
      className={className}
      {...props}
    >
      <path
        d="M7 10L9 12L13 8M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckCircleIcon = memo(CheckCircleIconSvg);
CheckCircleIcon.displayName = 'CheckCircle';
