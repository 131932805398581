import { first, isNil, last } from 'lodash';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { Model } from 'survey-core';
import 'survey-core/defaultV2.min.css';
import { Survey } from 'survey-react-ui';
import { useWorkflowNavigation } from '../../../../../shared/view/components/Workflow';
import { BackHeader } from '../BackHeader';
import { ScreenContentContainer } from '../ScreenContentContainer';
import './index.css';
import { surveyStyle } from './survey.style';

type SurveyPageProps = {
  setSurveyObject: (data: Record<string, string>) => void;
  model: Model;
  surveyLabel: string;
  defaultData?: Record<string, string>;
  progressBarOffset?: number;
};

const isPageInModel = (
  model: Model,
  somePageName: string | undefined,
): boolean => {
  if (isNil(somePageName)) {
    return false;
  }
  return !isNil(model.getPageByName(somePageName));
};

const SurveyPage = (props: SurveyPageProps) => {
  const { model, setSurveyObject, defaultData = {} } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const pageNameFromUrl = useCallback(
    () => last(location.pathname.split('/')),
    [location.pathname],
  );

  const pageFromURL = pageNameFromUrl();
  const initialPageToLoad = isPageInModel(model, pageFromURL)
    ? pageFromURL
    : first(model.pages)?.name;

  const [pageName, setPageName] = useState(initialPageToLoad);
  const workflowNavigation = useWorkflowNavigation().navigation;

  useEffect(() => {
    const newPageName = pageNameFromUrl();
    setPageName(newPageName);
  }, [location.pathname, pageNameFromUrl]);

  /*
  When SurveyJS changes page, update location
  - To prevent circular depency, do not navigate if the page is currently shown
    - ex : user answers question -> page changes -> location changes -> page changes again
    - as the SurveyPage loads initially without a specific page, we use navigate's replace to replace then initial entry when mounting for the first time
  */
  useEffect(() => {
    if (!isNil(pageName)) {
      if (pageNameFromUrl() !== pageName) {
        const replace = !isPageInModel(model, pageNameFromUrl());
        navigate(pageName, { replace });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName, model]);

  /*
  Using onAfterRenderPage instead of onCurrentPageChanged
  - because onCurrentPageChanged is not called for the first page
  */
  const onAfterRenderPage = useCallback(
    (sender: Model) => {
      setPageName(sender.currentPage.name);
    },
    [setPageName],
  );

  const onValueChanged = useCallback(
    (survey: Model) => {
      setSurveyObject(survey.data);
    },
    [setSurveyObject],
  );

  /*
  Using onCompleting instead of onComplete
  - because onCompleting allows us not to disallow completing the survey
  - thus avoiding SurveyJS to show a complete page when we go back after completing the survey
  */
  const onCompleting = useCallback(
    // TODO: remove any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (survey: Model, options: any) => {
      setSurveyObject(survey.data);
      options.allow = false;
      workflowNavigation.goNext();
    },
    [setSurveyObject, workflowNavigation],
  );

  /*
  Since the model is given as a props, it is persisted when this component is mounted multiple times
  - we have to clear the callbacks,
  - otherwise completing the survey multiple times when going back and forth in the workflow will subscribe the same callbacks multiple times
  */
  useEffect(() => {
    model.onAfterRenderPage.clear();
    model.onAfterRenderPage.add(onAfterRenderPage);
    model.onValueChanged.clear();
    model.onValueChanged.add(onValueChanged);
    model.onCompleting.clear();
    model.onCompleting.add(onCompleting);
    model.data = defaultData;
  }, [model, defaultData, onAfterRenderPage, onValueChanged, onCompleting]);

  useLayoutEffect(() => {
    // We want to do this whenever surveyJS updates so that we can hide the tooltips.
    // As we need to hide an element only if it's child is empty, we cannot do so using pure CSS as the required
    // selector is not supported on older browsers.
    document
      .querySelectorAll('.sd-question__description--under-input > span')
      .forEach((spanElement) => {
        if (spanElement.innerHTML === '') {
          if (spanElement.parentElement !== null) {
            spanElement.parentElement.style.display = 'none';
          }
        }
      });
  }, [model, pageName]);

  return (
    <ScreenContentContainer>
      <div className="flex flex-col flex-grow">
        <BackHeader />
        <Survey
          currentPage={pageName}
          model={model}
          showCompletePage={false}
          css={surveyStyle}
          goNextPageAutomatic={true}
          autoAdvanceDelay={0}
        />
      </div>
    </ScreenContentContainer>
  );
};

export default SurveyPage;
