type Props = {
  text: string;
  textColor: string;
  bgColor: string;
};

const Pill = ({ text, textColor, bgColor }: Props) => {
  return (
    <div
      className={`rounded-3xl px-12 py-8 border ${textColor} flex items-center`}
    >
      <div className={`rounded-full ${bgColor} w-[8px] h-[8px] mr-4`} />
      {text}
    </div>
  );
};

type PillsProps = {
  text: string;
};

export const GreenPill = ({ text }: PillsProps) => {
  return <Pill text={text} textColor="text-teal-500" bgColor="bg-teal-500" />;
};

export const OrangePill = ({ text }: PillsProps) => {
  return (
    <Pill text={text} textColor="text-orange-500" bgColor="bg-orange-500" />
  );
};
