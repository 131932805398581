import type { FunctionComponent, PropsWithChildren } from 'react';
import { Typography } from '../Typography/Typography';

type Props = PropsWithChildren<{
  isLoading: boolean;
}>;

export const LoadingView: FunctionComponent<Props> = ({
  isLoading,
  children,
}) => {
  if (!isLoading) {
    return <>{children}</>;
  }

  return (
    <div
      className="flex flex-col flex-grow items-center justify-center text-center"
      aria-label="loading"
      aria-live="polite"
      role="status"
    >
      <Typography.P1>{'Loading...'}</Typography.P1>
    </div>
  );
};
