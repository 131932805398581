import { t } from '@lingui/macro';
import { useEffect } from 'react';
import { ErrorPage } from '../../../shared/view/components/ErrorPage/ErrorPage';
import { LoadingView } from '../../../shared/view/components/LoadingView/LoadingView';
import { useWorkflowNavigation } from '../../../shared/view/components/Workflow';
import { useOnboardingStore } from '../shared/domain/onboarding.store';
import { OnboardingTemplateScreen } from '../shared/view/OnboardingTemplateScreen';
import { filterProtocolCategoriesById } from './domain/filterProtocols';
import { useGetProblems } from './infra/useGetProblems';
import { ProblemItem } from './view/ProblemItem';

export const SelectSubProblemScreen = () => {
  const { data, loading, error, refetch } = useGetProblems();
  const { navigation } = useWorkflowNavigation();
  const { setOnboardingAnswers, protocolCategoryId, protocolId } =
    useOnboardingStore((store) => ({
      setOnboardingAnswers: store.setOnboardingAnswers,
      protocolCategoryId: store.protocolCategoryId,
      protocolId: store.protocolId,
    }));

  useEffect(() => {
    if (loading || error || data === undefined) return;
    const subprotocols = filterProtocolCategoriesById(
      data.protocolCategories,
      protocolCategoryId,
    );
    if (subprotocols === undefined) {
      navigation.goPrevious();
    }
  }, [loading, error, navigation, data, protocolCategoryId]);

  if (error || (!loading && !data)) {
    return <ErrorPage error={error} onRetry={refetch} isRetrying={loading} />;
  }
  if (loading || !data) {
    return (
      <OnboardingTemplateScreen title={t`selectsubproblem.title`}>
        <LoadingView isLoading={loading} />
      </OnboardingTemplateScreen>
    );
  }

  const subprotocols = filterProtocolCategoriesById(
    data.protocolCategories,
    protocolCategoryId,
  );

  const onProblemItemClicked = (id: string, title: string) => {
    setOnboardingAnswers({
      protocolId: id,
      protocolName: title,
    });
    navigation.goNext();
  };

  return (
    <OnboardingTemplateScreen title={t`selectsubproblem.title`}>
      {subprotocols?.protocols.map((subCategory) => (
        <div key={subCategory.title} className="w-full">
          <ProblemItem
            className={`${
              subCategory.id === protocolId ? 'bg-grey-700' : 'bg-grey-800'
            } `}
            onClick={() =>
              onProblemItemClicked(subCategory.id, subCategory.title)
            }
            label={subCategory.title}
          />
          <div className="h-8" />
        </div>
      ))}
    </OnboardingTemplateScreen>
  );
};
