import { t } from '@lingui/macro';
import { Typography } from '../../../../shared/view/components/Typography/Typography';

export type ProgressBarProps = {
  label: string;
  currentStep: number;
  maxStep: number;
  showSteps?: boolean;
  className?: string;
};

export const ProgressBar = ({
  label,
  currentStep,
  maxStep,
}: ProgressBarProps) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row justify-between w-full">
        <Typography.P3 className="text-grey-100 font-semibold">
          {label}
        </Typography.P3>
        <Typography.P4 className="text-grey-300">
          {currentStep}
          {t`progress-bar.out-of`}
          {maxStep}
        </Typography.P4>
      </div>
      <div className="h-8" />
      <div className="w-full bg-grey-700 rounded-full h-4 dark:bg-gray-700">
        <div
          className="h-4 bg-rainbow rounded-full transition-all"
          style={{ width: `${(currentStep / maxStep) * 100}%` }}
        />
      </div>
    </div>
  );
};

export const CentralProgessBar = ({
  label,
  currentStep,
  maxStep,
}: ProgressBarProps) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row justify-center w-full">
        <Typography.P1 className="text-grey-100 font-semibold">
          {label}
        </Typography.P1>
      </div>
      <div className="h-16" />
      <div className="w-full bg-grey-700 rounded-full h-4 dark:bg-gray-700">
        <div
          className="h-4 bg-rainbow rounded-full transition-all"
          style={{ width: `${(currentStep / maxStep) * 100}%` }}
        />
      </div>
    </div>
  );
};
