import { useCallback, useEffect } from 'react';

export const CLOSE_IFRAME_SUCCESS = 'CLOSE_IFRAME_SUCCESS';
export const CLOSE_IFRAME_FAILURE = 'CLOSE_IFRAME_FAILURE';

export const notifySuccess = () => {
  postMessageToMainWindow(CLOSE_IFRAME_SUCCESS);
};

export const notifyFailure = () => {
  postMessageToMainWindow(CLOSE_IFRAME_FAILURE);
};

const postMessageToMainWindow = (message: string) => {
  // Raise if no opener and no parent ?
  window.opener?.postMessage(message, '*');
  window.parent?.postMessage(message, '*');

  window.close();
};

export const useListenerToSecureChallenge = ({
  onSuccess,
  onFailure,
}: {
  onSuccess: () => void;
  onFailure: () => void;
}) => {
  const receiver = useCallback(
    (event: MessageEvent) => {
      switch (event.data) {
        case CLOSE_IFRAME_SUCCESS:
          onSuccess();
          return;
        case CLOSE_IFRAME_FAILURE:
          onFailure();
          return;
      }
    },
    [onSuccess, onFailure],
  );
  useEffect(() => {
    window.addEventListener('message', receiver, false);
    return () => window.removeEventListener('message', receiver, false);
  }, [receiver]);
};
