import { memo } from 'react';

const ChatSvg: React.FC = () => {
  return (
    <svg width="41" height="40" fill="none">
      <path
        stroke="#FDFDFD"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M13.83 16.67h13.34m-13.34 6.66h6.67M18.83 5h3.34a15 15 0 1 1 0 30H10.5a6.67 6.67 0 0 1-6.67-6.67V20a15 15 0 0 1 15-15Z"
      />
    </svg>
  );
};

export const ChatIcon = memo(ChatSvg);
ChatIcon.displayName = 'Chat';
