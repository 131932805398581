import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import appConfig from './shared/config/appConfig';

Sentry.init({
  dsn: appConfig.sentryDsn,
  integrations: [new BrowserTracing()],
  enableTracing: process.env.NODE_ENV !== 'development',

  // Ajust this value to control the sample rate
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
    <App />
    {/* </BrowserRouter> */}
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
