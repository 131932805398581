import type { FunctionComponent } from 'react';
import { Typography } from '../Typography/Typography';

type Props = {
  label: string;
  Icon: FunctionComponent;
  className?: string;
  textClassName?: string;
};

export const Tooltip = ({
  label,
  Icon,
  className = '',
  textClassName = '',
}: Props) => {
  return (
    <div
      className={`bg-grey-800 p-12 rounded-lg gap-12 flex items-center ${className}`}
    >
      <div>
        <Icon />
      </div>
      <Typography.P3 className={`text-grey-100 ${textClassName}`}>
        {label}
      </Typography.P3>
    </div>
  );
};
