import { t } from '@lingui/macro';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { LoadingView } from '../../shared/view/components/LoadingView/LoadingView';
import { BackHeader } from '../Onboarding/shared/view/BackHeader';
import { ScreenContentContainer } from '../Onboarding/shared/view/ScreenContentContainer';
import SurveyPage from '../Onboarding/shared/view/SurveyPage';
import { useCheckInSurveyStore } from './domain/checkinSurvey.store';

export const CheckInScreen = () => {
  const { checkInSurvey, checkInSurveyAnswers, setCheckInSurveyAnswers } =
    useCheckInSurveyStore(
      useCallback(
        (store) => ({
          checkInSurveyAnswers: store.checkInSurveyAnswers,
          setCheckInSurveyAnswers: store.setCheckInSurveyAnswers,
          checkInSurvey: store.checkInSurvey,
        }),
        [],
      ),
    );

  const formattedDay = format(new Date(), 'dd/MM');

  const onSurveyComplete = useCallback(
    (data?: Record<string, string>) => {
      if (!data) {
        return null;
      }
      setCheckInSurveyAnswers(data);
      return null;
    },
    [setCheckInSurveyAnswers],
  );

  if (!checkInSurvey) {
    return (
      <ScreenContentContainer>
        <BackHeader />
        <LoadingView isLoading={true} />
      </ScreenContentContainer>
    );
  }

  return (
    <SurveyPage
      model={checkInSurvey}
      surveyLabel={t`checkin.progress-bar ${formattedDay}`}
      setSurveyObject={onSurveyComplete}
      defaultData={checkInSurveyAnswers ?? {}}
    />
  );
};
