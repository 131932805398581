import { memo } from 'react';
import type { IconType } from './Icon.types';

const DEFAULT_ICON_SIZE_PX = 24;

const SpinnerIconSvg: IconType = ({
  size = DEFAULT_ICON_SIZE_PX,
  className = '',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={`stroke-grey-0 ${className}`}
      {...props}
    >
      <circle cx="12" cy="12" r="11.04" strokeWidth="1.92" opacity=".15" />
      <mask id="a" fill="#fff">
        <path d="M24 12A12 12 0 0 0 12 0v1.92A10.08 10.08 0 0 1 22.08 12H24Z" />
      </mask>
      <path
        strokeWidth="4"
        d="M24 12A12 12 0 0 0 12 0v1.92A10.08 10.08 0 0 1 22.08 12H24Z"
        mask="url(#a)"
      />
    </svg>
  );
};

export const SpinnerIcon = memo(SpinnerIconSvg);
SpinnerIcon.displayName = 'Spinner';
